import React from 'react'
import "./CApopUp.css"
import NFT from "../../assets/Images/NFT.svg"
import NFT2 from "../../assets/Images/NFT2.svg"
import CabgBlue from "../../assets/Images/CAbg-blue.png"
import CAbg from "../../assets/Images/CA_bg.png"

const CApopUp = ({data}) => {
    const linkedinClick = ()=>{
        window.open(data.linkedin, "_blank")
    }
  return (
    <div className="pop-frame">
        <div className='pop-left' style={{backgroundImage:data.uni==="BMS INSTITUTE OF TECHNOLOGY"?`url(${CAbg})`:`url(${CabgBlue})`}}>
            <img src={data.uni==="BMS INSTITUTE OF TECHNOLOGY"?NFT:NFT2}></img>
        </div>
        <div className='pop-right'>
            <div className='pop-right-top'>
            
            <h1 className='pop-name'>
                {data.firstname} {data.lastname}
            </h1>
            <div className='pop-desc'>
                <h3 className='description-title'>
                    UNIVERSITY
                </h3>
                <h3 className='descriptions'> {data.uni}</h3>
            </div>
            <div className='pop-desc'>
                <h3 className='description-title'>
                    COURSE
                </h3>
                <h3 className='descriptions'>
                    {data.course}
                </h3>
            </div>
            <div className='pop-desc'>
                <h3 className='description-title'>
                    LOCATION
                </h3>
                <h3 className='descriptions'>
                    {data.location}
                </h3>
            </div>
            <div className='pop-desc' onClick={linkedinClick}>
                <h2 className='linked-in-btn'>
                    VIEW ON LINKEDIN
                </h2>
            </div>
        </div>
        <div className='pop-right-bottom'>
            <div className='nft-title'>
        <h1 className='pop-name'>
        w3o /gen1
            </h1>
            {/* <div>
                <p className='rarity'>
                    ULTRA RARE
                </p>
            </div> */}
            </div>
            {/* <div className='pop-desc'>
                <h3 className='description-title'>
                    BACKGROUND
                </h3>
                <h3 className='descriptions'> PINK</h3>
            </div>
            <div className='pop-desc'>
                <h3 className='description-title'>
                    BODY
                </h3>
                <h3 className='descriptions'>
                    BLACK TUXEDO
                </h3>
            </div>
            <div className='pop-desc'>
                <h3 className='description-title'>
                    HEAD
                </h3>
                <h3 className='descriptions'>
                    SKULL
                </h3>
            </div>
            <div className='pop-desc'>
                <h2 className='linked-in-btn'>
                    VIEW ON OPENSEA
                </h2>
            </div> */}
           <h1 className='pop-name'> COMING SOON</h1>
        </div>
        </div>
    </div>
  )
}

export default CApopUp