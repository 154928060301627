import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import "../Course/CourseSyllabus.css";

const BASE_URL = 'https://auth.web3onwards.com';

const Redirection = () => {
  const [spinner, setSpinner] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => setSpinner(false), 2000);
  }, []);

  useEffect(() => {
    setSpinner(true)
    const handleAuthResponse = async () => {
      const params = new URLSearchParams(window.location.search);
      const token = params.get("JWT");

      if (token) {
        localStorage.setItem("jwtToken", token);
        console.log("JWT stored in localhost in /redirection");

        try {
          const response = await axios.get(`${BASE_URL}/user`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });

          const userInfo = response.data.User;
          localStorage.setItem("USN", userInfo.USNnumber);
          localStorage.setItem("userName", userInfo.userName);
          localStorage.setItem("fullName", userInfo.fullName);
          localStorage.setItem("college", userInfo.college);
          localStorage.setItem("__v", userInfo.__v);

          navigate("/course");
        } catch (error) {
          console.error("Failed to fetch user details:", error);
        }
        finally{setSpinner(false)
       }
      }
    };

    handleAuthResponse();
  }, [navigate]);


  return (
    <div
        id="spinner"
        className="spin-container"
        style={{ display: !spinner && "none" }}
      >
        <div className="loading"></div>
      </div>
  );
};

export default Redirection;
