import React, { useRef, useEffect,useState} from "react";
//import { useUserContext } from "../CourseLogin/UserContext";
import "./coursepagestyle.css";
import MSRITLogo from "../../../assets/Images/MSRIT.svg";
import DeflixLogo from "../../../assets/Images/dFlix.svg";
import SeeContributionLogo from "../../../assets/Images/seecontri.svg";
import Profile from "../../../assets/Images/profileimg.jpeg";
import PlayButton from "../../../assets/Images/play_circle.svg";
import gotoTask from "../../../assets/Images/goto_task.svg";
import playTask from "../../../assets/Images/play_task.svg";
import alarm from "../../../assets/Images/alarm.svg";
import taskStatus from "../../../assets/Images/task_status.svg";
import PauseButton from "../../../assets/Images/pause.svg"
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
const TRACKING_ID = "G-F6QZ1VY3NY";



const User= ({ courseType }) => {
  //const { USNnumber } = useUserContext();
  const progressBarRef = useRef(null);
  const progress = 39;
  const videoRef = useRef(null);
  const navigate = useNavigate();

  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [timelineProgress, setTimelineProgress] = useState(0);
  const [isHovered, setIsHovered] = useState(false); 
  const [courseDetails, setCourseDetails] = useState({
    hours: 0, 
    courses: 0, 
    tasks: 0, 
    progressPercentage: 0,
    lastInProgressTaskWithCourse: null,
  });

  const apiBaseUrl = 'https://auth.web3onwards.com';
  const defaultUSNnumber ='1W3O2024';
  const defaultjwt = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NjlmNGEzMDJjN2NjMzcwMzYyMjNmOGYiLCJVU05udW1iZXIiOiIxVzNPMjAyNCIsImlhdCI6MTcyMTcxNTI4OH0.7DpOPyaq2LI09eawOJFjXSW7Bj-9ANkQ6WilsDfmABY';


  const updateProgress = () => {
    const video = videoRef.current;
    if (video) {
      const value = (video.currentTime / video.duration) * 100;
      setTimelineProgress(value);
    }
  };

  useEffect(() => {
    const fetchCourseDetails = async () => {
      const USNnumber = localStorage.getItem("USN") || defaultUSNnumber; 
      const jwt = localStorage.getItem('jwtToken') || defaultjwt; 
      console.log(USNnumber)
      const url = `${apiBaseUrl}/usercourseminutes/${courseType}?USNnumber=${USNnumber}`;
        
      try {
        const response = await fetch(url, {
          headers: {
            'Authorization': `Bearer ${jwt}`, // Include the JWT in the Authorization header
          }
        });
        if (response.ok) {
          const jsonData = await response.json();
          const details = jsonData.courseMinutesDetails;
          setCourseDetails({
            hours: Math.round(details.totalCourseMinutesRemaining / 60 * 10) / 10,
            courses: details.totalCoursesRemaining,
            tasks: details.remainingTasksCount,
            progressPercentage: Math.round(details.coursePercentageCompleted),
            lastInProgressTaskWithCourse: details.lastInProgressTaskWithCourse,
          });
        } else {
          throw new Error(`Failed to fetch course details: ${response.status}`);
        }
      } catch (error) {
        console.error('Error fetching course details:', error);
      }
    };

    if (courseType) {
      fetchCourseDetails();
    }
  }, [courseType]);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.addEventListener("timeupdate", updateProgress);
      video.addEventListener("play", () => setIsVideoPlaying(true));
      video.addEventListener("pause", () => setIsVideoPlaying(false));
      return () => {
        video.removeEventListener("timeupdate", updateProgress);
        video.removeEventListener("play", () => setIsVideoPlaying(true));
        video.removeEventListener("pause", () => setIsVideoPlaying(false));
      };
    }
  }, []);

  const togglePlay = () => {
    if (videoRef.current) {
      if (isVideoPlaying) {
        videoRef.current.pause();
        setIsVideoPlaying(false);
  
        // Track the video pause event
        ReactGA.event({
          category: "Video",
          action: "Video Pause",
          label: "Student paused the video",
        });
      } else {
        videoRef.current.play();
        setIsVideoPlaying(true);
  
        // Track the video play event
        ReactGA.event({
          category: "Video",
          action: " Video Play",
          label: "Student played the video",
        });
      }
    }
  };
  
  // useEffect(() => {
  //   console.log("User USN:", USNnumber); 
  // }, [USNnumber]);

  useEffect(() => {
    const progressBar = progressBarRef.current;
    if (progressBar) {
      progressBar.style.width = `${courseDetails.progressPercentage}%`;
    }
  }, [courseDetails.progressPercentage]);

  const formatTime = (minutes) => {
    if (minutes < 60) {
      return `${minutes}mins`;
    } else {
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;
      return `${hours}hr ${mins > 0 ? `${mins}mins` : ''}`;
    }
  };

  const handleGotoTaskClick = () => {
    if (courseDetails.lastInProgressTaskWithCourse) {
      navigate('/course-description', {
        state: {
          fromGotoTask: true,
          courseId: courseDetails.lastInProgressTaskWithCourse.task.courseId,
          courseName: courseDetails.lastInProgressTaskWithCourse.courseName,
          courseType: courseDetails.lastInProgressTaskWithCourse.courseType,
          level: courseDetails.lastInProgressTaskWithCourse.task.level,
          linkOfTask: courseDetails.lastInProgressTaskWithCourse.task.linkOfTask,
          discordLink: courseDetails.lastInProgressTaskWithCourse.task.discordLink,
          submissionLink: courseDetails.lastInProgressTaskWithCourse.task.submissionLink

        }
      });
      console.log("Navigating to courseDescription with")
    }
  };

  const progressBarStyles = {
    height: "2.25rem",
    backgroundColor: "#2CBC3B",
    backgroundImage:
    "linear-gradient(135deg, rgba(49, 236, 61, 0.3) 25%, transparent 25%, transparent 50%, rgba(49, 236, 61, 0.3) 50%, rgba(49, 236, 61, 0.3) 75%, transparent 75%, transparent)",
    backgroundSize: "1.5rem 1.5rem",
    // textAlign: "center",
    lineHeight: "2.25rem",
    color: "white",
    width: "0%",
    transition: "width 1s ease-in-out",
  };
  return (
    <>
    <div className="user-container">
    <div className="course-page-container">
      <div className="course-page-left-grid">
      {!courseDetails.lastInProgressTaskWithCourse ? (
        <div className="user-box-container">
          <div className="box-1">
            <p className="box-1-heading">TRANSFORM LEARNING INTO DOING</p>
          </div>
          <div className="box-2">
            <div className="team-container">
            <p className="box-2-text">
              CURRENTLY PRODUCT DEVELOPMENT TEAM CONSISTS OF 20 STUDENTS AND
              UPSKILLING TEAM CONSISTS OF 45 STUDENTS
            </p>
            </div>
            <div className="countdown-container">
            <p className="box-2-text">
              IN <span className="statistic-value">{courseDetails.hours}</span> HOURS
              AND <span className="statistic-value">{courseDetails.courses}</span>COURSES
              WITH <span className="statistic-value">{courseDetails.tasks}</span> TASKS
              YOU CAN START CONTRIBUTING TO PRODUCT DEVELOPMENT TEAM
            </p>
            </div>
            <div
              className="progress-container"
              style={{
                width: "100%",
                height: "2.25rem",
                border: "1px solid black",
                position: "relative",
                marginBottom:"2.25rem",
              }}
            >
              <div
                className="progress-bar"
                ref={progressBarRef}
                style={progressBarStyles}
              >
                <span className="progress-para">YOUR SUCCESS..</span>
                <span className="progress-text">{courseDetails.progressPercentage}%</span>
              </div>
            </div>
          </div>
        </div>
        ) : (
          <div className="user-box-container">
          <div className="box-2">
            <div className="team-container">
            <p className="box-2-text">
              CURRENTLY PRODUCT DEVELOPMENT TEAM CONSISTS OF 20 STUDENTS AND
              UPSKILLING TEAM CONSISTS OF 45 STUDENTS
            </p>
            </div>
            <div
              className="progress-container"
              style={{
                width: "100%",
                height: "2.25rem",
                border: "1px solid black",
                position: "relative",
                marginBottom:"2.25rem",
              }}
            >
              <div
                className="progress-bar"
                ref={progressBarRef}
                style={progressBarStyles}
              >
                <span className="progress-para">YOUR SUCCESS..</span>
                <span className="progress-text">{courseDetails.progressPercentage}%</span>
              </div>
            </div>
            <div className="countdown-container">
            <p className="box-2-text">
              IN <span className="statistic-value">{courseDetails.hours}</span> HOURS
              AND <span className="statistic-value">{courseDetails.courses}</span>COURSES
              WITH <span className="statistic-value">{courseDetails.tasks}</span> TASKS
              YOU CAN START CONTRIBUTING TO PRODUCT DEVELOPMENT TEAM
            </p>
            </div>
          <div className="task-container">
          <div className="task-header-container">
            < h3 className="task-header">Let's start where you left of...., Tasks you left incompleted</h3>
          </div>
          <div className="task-content-container">
          <div className="task-info-container">
          <div className="task-no-container">
          <h3 className="task-no">TASK {courseDetails.lastInProgressTaskWithCourse?.task?.taskId ?? ''}</h3>
          </div>
         
    <div className="goto-task-container"  onClick={handleGotoTaskClick}>
      <img src={gotoTask} alt="gotoTask" />
    </div>
  

          </div>
          <div className="course-level-info-outer-container">
          <div className="vector-container"></div>
          <div className="course-level-info-container">
          <div className="task-course-container">
          <h3 className="task-course">{courseDetails.lastInProgressTaskWithCourse?.courseName?? ''} </h3>
          <h3 className="task-course-dot"> • </h3>
          </div>
          <div className="task-level-container">
          <h3 className="task-level">{courseDetails.lastInProgressTaskWithCourse?.task?.level ?? ''}</h3> 
          </div>
          </div>
          <div className="vector-container"></div>
          </div>
          <div className="task-status-info-outer-container">
          <div className="task-status-info-container">
            <div className="task-name-time-container">
            <div className="task-name-container">
            <img className="task-play-icon-container" src= {playTask} alt="play Task"/>
              <h4 className="task-name">{courseDetails.lastInProgressTaskWithCourse?.task?.taskName ?? ''}</h4>
            </div>
            <div className="task-time-container">
            <img className="task-play-icon-container" src= {alarm} alt="alarm"/>
              <h4 className="task-name">{formatTime(courseDetails.lastInProgressTaskWithCourse?.task?.taskMinutes ?? '')}</h4>
            </div>
            </div>
            <div className="task-description-container">
              <p className="task-description">{courseDetails.lastInProgressTaskWithCourse?.task?.taskDescription ?? ''}</p>
            </div>
          </div>
          <div className="task-status-icon-container">
          <img src= {taskStatus} alt="taskStatus"/>
          </div>
          </div>
          </div>
        </div>
        </div>
        </div>

        )}
      </div>

      <div className="course-page-right-grid">
        <div className="profile-container">
          <div className="profile">
            <div className="profile-video-container"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            >
            { !isVideoPlaying && (
                  <div className="video-overlay">
                    <div className="overlay-content">
                      <div className="overlay-header-container">
                        <h3 className="overlay-header">What is web3onwards?</h3>
                      </div>
                      <div className="overlay-desciption-container">
                        <p className="overlay-text">Welcome to web3onwards, a community dedicated to the exploration, education, and innovation of decentralised technologies. Our mission is to empower the next generation of Marketers, Managers, Designers and Develop</p>
                      </div>
                      <div className="play-btn-container">
                      <img  className="play-btn"src={PlayButton} alt="Play Button"onClick={togglePlay}/>
                      </div>
                      <div className="playbutton-description-container">
                      <p className="playbutton-description">See what the students say <br></br>about web3 onwards</p>
                      </div>
                    </div>
                  </div>
                )}
              {/* <img className="logo" src={MSRITLogo} alt="MSRIT Logo" /> */}
              
              <div className="timeline" style={{ width: `${timelineProgress}%` }}></div>
              <video
                src="https://webthreeonwards.blr1.cdn.digitaloceanspaces.com/w3o%20team.mp4"
                className="profile-vid"
                controls
                ref={videoRef}
              ></video>
               {isHovered && isVideoPlaying && (
          <img  className="pause-btn"src={PauseButton} onClick={togglePlay} alt="Pause Button " />
        
       
    )}
            </div>
            <div className="user-details-container">
            <div className="name-container">
              <div className="name">NITIN GOEL</div>
              </div>
              <div className="box-1">
                <div className="deflix-contributor-container">
                <p className="text">
                  Contributor of{" "}
                  <span>
                    <img src={DeflixLogo} alt="" />
                  </span>{" "}
                </p>
                </div>
                <div className="role-box-container">
                <div className="box">Blockchain Developer</div>
                </div>
              </div>
            
            </div>
          </div>
          <div className="contribution">
  <a
    href="http://143.244.134.9:5173/contributors"
    target="_blank"
    onClick={() => {
      ReactGA.event({
        category: "Image",
        action: "Clicks to see Contributors",
        label: "no of clicks to see Deflix Contributors",
      });
    }}
  >
    <img src={SeeContributionLogo} alt="See Contributions" />
  </a>
</div>

        </div>
      </div>
    </div>
    </div>
    </>
  );
};

export default User;
