import React, { useState } from 'react'
import "./Accordion.css"
const Accordion = () => {
    const [activeIndex, setActiveIndex] = useState(null);
    const accordionItems = [
        {
          header: 'What is web3onwards?',
          content: 'A community initiated by NFThing where we are building the new internet by empowering our members to come together to create community-owned products that aim to create innovative blockchain based solutions that address real-world problems.',
        },
        {
          header: ' What makes web3onwards unique and why should I join?',
          content: "web3onwards is a community for university students interested in building and living in a web3 world. The community first upskills with a full blockchain technology background and then goes about building tools using this technology for the betterment of people around us. Through shared ownership and tokenomics the community will also have economic benefits that should incentivise members in the community to contribute towards building these products. Besides the technical advantages and the tokenomics, you'll also become part of a diverse network of engineers, designers, marketers, and strategists. Incubate your ideas: our community nurtures an entrepreneurial mindset, enabling you to form a team, collaborate, and create exciting products.",
        },
        {
          header: 'Who should join this community and how?',
          content: "Students at any level of their undergraduate course can join this community. We also welcome individuals who are passionate to learn and contribute to web3, who want to develop an entrepreneurship mindset. If you want to gain skills and work on exciting web3 projects, join our discord to be part of the community. We also partner with colleges to unlock exclusive channels and we host events for the students. Contact us to start the partnership process with your college today. Any web3 enthusiast is also welcome to join the server and continue their learning journey with us.",
        },
        {
          header: "What are community projects, how does this benefit members in terms of credibility?",
          content: 'Community projects in web3 are often open-source and community-driven, meaning that anyone can contribute to the project and collaborate with others to achieve the common goal. This benefits members by showcasing expertise, building reputation, networking opportunities, developing portfolios, and gaining recognition. Apart from the tokenomics, contributors will also be explicitly mentioned in the website of the live deployed project. This will significantly help in job placements and building credibility within the industry.',
        },
        {
          header: 'I want to represent web3onwards in our college as a Campus Ambassador. What is the role of a CA and how can I apply?',
          content: 'You are currently enrolled in the 2nd or 3rd year of any UG course with basic knowledge of marketing related concepts, willingness to learn and you have an enthusiastic and entrepreneurial personality \n You will promote and represent web3onwards on campus and online\n Form and manage a core team - designers, content writer, etc \nFrame a good strategy to keep the community growing inside the university by conducting weekly, monthly and quarterly events \nReach out to us on discord or via mail to represent your college as a Campus Ambassador',
        },
        {
          header: "Benefits of being CA of this community",
          content: 'Technical guidance from the industry leaders \nExpand your network and connections\nAccess to exclusive events and job opportunities\nValuable marketing and community building experience\nCredibility in building and maintaining a web3 community',
        },
        // Add more items as needed
      ];
    
    // Function to handle accordion header clicks
    const onAccordionHeaderClick = (index) => {
      setActiveIndex(index === activeIndex ? null : index);
    };
  
    // Generate the accordion items with headers and content
    const renderedItems = accordionItems.map((item, index) => {
      const isActive = index === activeIndex ? 'active' : '';
  
      let content = item.content;

      // Check if it's the 5th item and transform content into a list
      if (index === 4 || index===5) {
        const contentLines = content.split('\n'); // Split content into lines
        content = (
          <ul>
            {contentLines.map((line, idx) => (
              <li key={idx}>{line}</li>
            ))}
          </ul>
        );
      }
  
      return (
        <React.Fragment key={index}>
          <div className={`accordion-header ${isActive}`} onClick={() => onAccordionHeaderClick(index)}>
            {item.header}
          </div>
          <div className={`accordion-content ${isActive}`}>
            {content}
          </div>
          <div className='accordion-border'></div>
        </React.Fragment>
      );
    });
  
    return <div className="accordion">{renderedItems}</div>;
  };
  
  export default Accordion;
  

