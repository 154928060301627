import React from "react";
import "./Footer.css"
import { useState, useEffect } from "react";
import { BsInstagram } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";


const Footer = () => {
  const [spinner, setSpinner] = useState(true);
  const [email, setEmail] = useState("");
  const [emailValid, setemailValid] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const url = "https://api.nfthing.com/nfthingSubscribe";
  const [word, setWord] = useState('DIFFERENTLY');

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setEmail(value);
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
          setemailValid(true);
        } else {
          setemailValid(false);
        }
      };
    
      const handleSubmit = () => {
        console.log("clicked");
        setSubmitClicked(true);
        if (emailValid) {
          const requestOptions = {
            // mode: 'no-cors',
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              email: email,
            }),
          };
          fetch(url, requestOptions)
            .then((response) => response.json())
            .then((data) => {
              if (data.code == 100) {
                alert(data.message);
              } else {
                alert("Server error try again later");
              }
            });
        } else {
          alert("Enter a valid email Id");
        }
      };
    
      const handleNfthing = ()=>{
        window.open("https://nfthing.com/", "_blank")
      }
    
      const handleFormClick = () => {
        window.open("/login", "_blank");
      };
    
      const handleContactClick = () => {
        window.open("/form/contact", "_blank");
      };
    

return (
<>
<div className="footer">
<div className="footer-top">
  <div className="footer-top-left">
    <h1>ABOUT US</h1>
    <p>
      web3onwards is an initiative to create a new generation of
      community that are builders and users of their products.{" "}
    </p>
  </div>
  <div className="footer-top-mid">
    <div className="footer-join">
      <h1>JOIN NOW</h1>
      <a href="https://discord.gg/GuMe3b5kFT" target="_blank">
      <p>Join community</p>
      </a>
      <p>Contact Us</p>
    </div>
    <div className="footer-socials">
      <h1>FOLLOW US</h1>
      <a
        href="https://www.linkedin.com/company/web3onwards/"
        target="_blank"
        rel="noreferrer"
      >
        <FaLinkedinIn /> LinkedIn
      </a>
      {/* <a href='https://twitter.com/nfthing_' target="_blank">
          <BsTwitter/> Twitter
      </a> */}
      <a
        href="https://www.instagram.com/web3onwards/"
        target="_blank"
        rel="noreferrer"
      >
        <BsInstagram /> Instagram
      </a>
    </div>
  </div>
  <div className="footer-top-right">
    <h1>web3onwards</h1>
    <div className="footer-initiative" onClick={handleNfthing}>
      <p>INITIATIVE BY NFTHING</p>
    </div>
  </div>
</div>
<div className="footer-mid">
  <div className="mid-container">
    <h3 style={{ minWidth: "195px" }}>
      Want more out of us? Join our newsletter
    </h3>
    <div className="email">
      <input
        type={"email"}
        placeholder="Enter you email address"
        className="ip-box"
        onChange={(e) => handleInputChange(e)}
        value={email}
      ></input>
      <input
        type={"submit"}
        className="submit-button"
        value={"SUBMIT"}
        onClick={handleSubmit}
      />
    </div>
  </div>
</div>
<div className="footer-bottom">
  <p>© 2024 web3onwards</p>
  <div>
    <p>PRIVACY POLICY</p>
    <p>TERMS AND CONDITIONS</p>
  </div>
</div>
</div>
</>
    );
};

export default Footer;