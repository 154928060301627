// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../assets/Fonts/PPNeueBit-Bold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../../assets/Fonts/N27-Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../../../assets/Fonts/N27-Bold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../../../assets/Fonts/N27-Light.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: PP-Nuebit;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
@font-face {
  font-family: N27;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
@font-face {
  font-family: N27-Bold;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}
@font-face {
  font-family: N27-Light;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}

.mint-box-2 {
  border: 1px solid #3d3d3d;
  width: 65%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  .box-2-mint-inner-box {
    width: 90%;
    height: 90%;
    display: flex;
    .box-2-mint-inner-left-box {
      width: 50%;
      height: 100%;
      font-family: N27-Light;
      text-align: left;
      line-height: 2rem;
      .mint-cert-box {
        margin-top: 4rem;
      }
    }
    .box-2-inner-right-box {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Pages/CourseDescription/MintCertificateBox/MintCertificateBox.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,4CAAuD;AACzD;AACA;EACE,gBAAgB;EAChB,4CAAoD;AACtD;AACA;EACE,qBAAqB;EACrB,4CAAiD;AACnD;AACA;EACE,sBAAsB;EACtB,4CAAkD;AACpD;;AAEA;EACE,yBAAyB;EACzB,UAAU;EACV,YAAY;;EAEZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB;IACE,UAAU;IACV,WAAW;IACX,aAAa;IACb;MACE,UAAU;MACV,YAAY;MACZ,sBAAsB;MACtB,gBAAgB;MAChB,iBAAiB;MACjB;QACE,gBAAgB;MAClB;IACF;IACA;MACE,UAAU;MACV,YAAY;MACZ,aAAa;MACb,uBAAuB;MACvB,mBAAmB;IACrB;EACF;AACF","sourcesContent":["@font-face {\n  font-family: PP-Nuebit;\n  src: url(\"../../../../assets/Fonts/PPNeueBit-Bold.otf\");\n}\n@font-face {\n  font-family: N27;\n  src: url(\"../../../../assets/Fonts/N27-Regular.otf\");\n}\n@font-face {\n  font-family: N27-Bold;\n  src: url(\"../../../../assets/Fonts/N27-Bold.otf\");\n}\n@font-face {\n  font-family: N27-Light;\n  src: url(\"../../../../assets/Fonts/N27-Light.otf\");\n}\n\n.mint-box-2 {\n  border: 1px solid #3d3d3d;\n  width: 65%;\n  height: 100%;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  .box-2-mint-inner-box {\n    width: 90%;\n    height: 90%;\n    display: flex;\n    .box-2-mint-inner-left-box {\n      width: 50%;\n      height: 100%;\n      font-family: N27-Light;\n      text-align: left;\n      line-height: 2rem;\n      .mint-cert-box {\n        margin-top: 4rem;\n      }\n    }\n    .box-2-inner-right-box {\n      width: 50%;\n      height: 100%;\n      display: flex;\n      justify-content: center;\n      align-items: center;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
