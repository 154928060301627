// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  align-items: center;
  text-align: center;
  background: #EDEDED;
 
}

* ::-webkit-scrollbar {
  width: 7px;
  height: 2px;
  background-color: #EDEDED;
}

* ::-webkit-scrollbar-thumb {
  background-color: #b3b3b3;
  border-radius: 4px;
}

@media only screen and (max-width: 600px){
  .App{
    overflow-x: hidden;
}

}  `, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;;AAErB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE;IACE,kBAAkB;AACtB;;AAEA","sourcesContent":[".App {\n  align-items: center;\n  text-align: center;\n  background: #EDEDED;\n \n}\n\n* ::-webkit-scrollbar {\n  width: 7px;\n  height: 2px;\n  background-color: #EDEDED;\n}\n\n* ::-webkit-scrollbar-thumb {\n  background-color: #b3b3b3;\n  border-radius: 4px;\n}\n\n@media only screen and (max-width: 600px){\n  .App{\n    overflow-x: hidden;\n}\n\n}  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
