import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./TaskSubmission.css";
import Image1 from "../../../assets/Images/image1.gif";
import Image2 from "../../../assets/Images/image2.gif";
import Image3 from "../../../assets/Images/image3.gif";
import Image4 from "../../../assets/Images/image4.gif";
import Image5 from "../../../assets/Images/image5.gif";
import Image6 from "../../../assets/Images/image6.gif";
import Image7 from "../../../assets/Images/image7.gif";
import Image8 from "../../../assets/Images/image8.gif";
import Footer from "../../Footer/Footer";
import web3button from "../../../assets/Images/web3button.svg";
import coursesbutton from "../../../assets/Images/coursesButton.svg";
import navContributors from "../../../assets/Images/nav_contri.svg";
import loginSignup from "../../../assets/Images/loginSignup.svg";


const TaskSubmission = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState("step1");
  const jwtToken = localStorage.getItem('jwtToken');
  const USN = localStorage.getItem('USN');
  const fullName = localStorage.getItem('fullName');
  const firstName = fullName ? fullName.split(' ')[0] : 'User';

  const handleLogout = () => {
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('USN');
    localStorage.removeItem('fullName');
    localStorage.removeItem('userName');
    localStorage.removeItem('college');
    localStorage.removeItem('__v');
    navigate('/courselogin');
  };

  const stepRefs = {
    step1: useRef(null),
    step2: useRef(null),
    step3: useRef(null),
    step4: useRef(null),
    step5: useRef(null),
    step6: useRef(null),
    step7: useRef(null),
    step8: useRef(null),
  };

  const handleScroll = () => {
    const offset = window.scrollY + window.innerHeight / 2;
    for (const [step, ref] of Object.entries(stepRefs)) {
      if (ref.current.offsetTop <= offset) {
        setActiveStep(step);
      }
    }
  };

  const scrollToStep = (step) => {
    setActiveStep(step);
    window.scrollTo({
      top: stepRefs[step].current.offsetTop - 20,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
    <div className="task-submission-container">
    <div className="nav-bar-container">
          <div className="page-navigation-container1">
          <div className="nav-button-container">
          <a href='/'>
          <img src={web3button} alt="web3button"/>
          </a>
          </div>
          <div className="nav-button-container">
            <a href='/course'>
          <img src={coursesbutton} alt="coursesButton" />
          </a>
          </div>
          </div>
          <div className="coursepage-navigation-container">
          <div className="nav-button-container">
          <a href='/'>
          <img className='nav-contributors-button' src={navContributors} alt="web3button"/>
          </a>
          </div>
          {jwtToken && USN ? (
                <div className="nav-user-button-container">
                  <button className="user-button">Hi, {firstName}</button>
                  <button className="logout-button" onClick={handleLogout}>LOGOUT</button>
                </div>
                
              ) : (
                <div className="nav-button-container">
                <a href='/courselogin'>
                  <img className='nav-loginSignup-button' src={loginSignup} alt="loginSignup" />
                </a>
                </div>
              )}
          </div>
        </div>
        <div className="task-submission-title-container">
        <h1 className="task-submission-title">Task Submission Guidelines</h1>
        </div>
        <div className="task-submission-sidebar">
        <ul className="task-submission-nav">
          {Object.keys(stepRefs).map((step) => (
            <li
              key={step}
              className={`task-submission-nav-item ${
                activeStep === step ? "active" : ""
              }`}
              onClick={() => scrollToStep(step)}
            >
              {step.replace("step", "Step ")}
            </li>
          ))}
        </ul>
      </div>


      <div className="task-submission-content">
      <div className="task-submission-step-container">

        <div
          ref={stepRefs.step1}
          className={`task-submission-step ${
            activeStep === "step1" ? "active-step" : ""
          }`}
        >
          <h2 className="task-submission-heading">Step 1: Start the Task</h2>
          <p className="task-submission-detail">
            To begin the process of completing your assignment, navigate to the
            designated platform and locate the "Start Task" button. This button
            is your gateway to accessing the specific tasks assigned to you.
          </p>
          <p className="task-submission-detail">
            If you have previously initiated the task, you may notice that the
            button has changed to "Pending." This indicates that the task is
            already in progress. In this case, simply click on the "Pending"
            button to resume where you left off.
          </p>
          <img
            src={Image1}
            alt="Start Task"
            className="task-submission-image"
          />
        </div>

        <div
          ref={stepRefs.step2}
          className={`task-submission-step ${
            activeStep === "step2" ? "active-step" : ""
          }`}
        >
          <h2 className="task-submission-heading">Step 2: Register for the Task</h2>
          <p className="task-submission-detail">
            Upon clicking the "Start Task" button, a new window will appear on
            your screen. This window is crucial as it enables you to officially
            register for the task.
          </p>
          <p className="task-submission-detail">
            To proceed, find and click on the "Register Here" button within this
            new window. This step is mandatory to ensure that your participation
            in the task is recorded and that you can submit your completed work
            later.
          </p>
          <img
            src={Image2}
            alt="Register Task"
            className="task-submission-image"
          />
        </div>

        <div
          ref={stepRefs.step3}
          className={`task-submission-step ${
            activeStep === "step3" ? "active-step" : ""
          }`}
        >
          <h2 className="task-submission-heading">Step 3: Join the Google Classroom</h2>
          <p className="task-submission-detail">
            Once you have registered for the task, a new tab will automatically
            open in your web browser, redirecting you to Google Classroom. This
            platform is integral for managing and submitting your coursework.
          </p>
          <ul className="task-submission-list">
            <li className="task-submission-item">
              Select your email, ensuring it is the one connected to your GitHub
              account and the same email address used for logging in.
            </li>
            <li className="task-submission-item">
              After selecting the appropriate email, click on the “Join class”
              button to become part of the classroom environment where the task
              is administered.
            </li>
          </ul>
          <img
            src={Image3}
            alt="Join Google Classroom"
            className="task-submission-image"
          />
        </div>

        <div
          ref={stepRefs.step4}
          className={`task-submission-step ${
            activeStep === "step4" ? "active-step" : ""
          }`}
        >
          <h2 className="task-submission-heading">Step 4: Return to the Web3onwards Tab</h2>
          <p className="task-submission-detail">
            After successfully joining the Google Classroom, it is time to
            return to the Web3onwards platform to complete your task submission.
          </p>
          <ul className="task-submission-list">
            <li className="task-submission-item">
              Navigate back to the tab where the Web3onwards platform is open.
            </li>
            <li className="task-submission-item">
              Locate and click on the "Submit Task" button to upload your
              completed assignment for review. This action finalizes your
              submission and moves your task to the next stage.
            </li>
          </ul>
          <img
            src={Image4}
            alt="Submit Task"
            className="task-submission-image"
          />
        </div>

        <div
          ref={stepRefs.step5}
          className={`task-submission-step ${
            activeStep === "step5" ? "active-step" : ""
          }`}
        >
          <h2 className="task-submission-heading">Step 5: Authorize GitHub Classroom</h2>
          <p className="task-submission-detail">
            For your submission to be properly integrated with GitHub, you will
            need to authorize GitHub Classroom. This step is crucial for linking
            your work with your GitHub account.
          </p>
          <ul className="task-submission-list">
            <li className="task-submission-item">
              You will be redirected to a new tab.
            </li>
            <li className="task-submission-item">
              Log in with your GitHub credentials if you are not already signed
              in.
            </li>
            <li className="task-submission-item">
              Click on the "Authorize GitHub Classroom" button to allow the
              necessary permissions for managing your assignment through GitHub.
            </li>
          </ul>
          <img
            src={Image5}
            alt="Authorize GitHub Classroom"
            className="task-submission-image"
          />
        </div>

        <div
          ref={stepRefs.step6}
          className={`task-submission-step ${
            activeStep === "step6" ? "active-step" : ""
          }`}
        >
          <h2 className="task-submission-heading">Step 6: Join the GitHub Classroom</h2>
          <p className="task-submission-detail">
            Next, you need to officially join the GitHub Classroom to access
            your assignment repository.
          </p>
          <ul className="task-submission-list">
            <li className="task-submission-item">A new window will open up.</li>
            <li className="task-submission-item">
              If prompted with options that are not required, such as choosing a
              role or setting up integrations, you can click on the "Skip to the
              next step" button to proceed directly to your assignment.
            </li>
          </ul>
          <img
            src={Image6}
            alt="Join GitHub Classroom"
            className="task-submission-image"
          />
        </div>

        <div
          ref={stepRefs.step7}
          className={`task-submission-step ${
            activeStep === "step7" ? "active-step" : ""
          }`}
        >
          <h2 className="task-submission-heading">Step 7: Accept the Assignment</h2>
          <p className="task-submission-detail">
            With your GitHub Classroom access confirmed, it is time to accept
            the specific assignment associated with the task.
          </p>
          <ul className="task-submission-list">
            <li className="task-submission-item">
              Click on the "Accept this Assignment" button. This action grants
              you access to the relevant repository where you will complete and
              submit your work.
            </li>
          </ul>
          <img
            src={Image7}
            alt="Accept Assignment"
            className="task-submission-image"
          />
        </div>

        <div
          ref={stepRefs.step8}
          className={`task-submission-step ${
            activeStep === "step8" ? "active-step" : ""
          }`}
        >
          <h2 className="task-submission-heading">Step 8: Access the Template Repository with Visual Studio Code</h2>
          <p className="task-submission-detail">
            To work on your assignment, you will need to access the template
            repository using Visual Studio Code, a popular code editor.
          </p>
          <ul className="task-submission-list">
            <li className="task-submission-item">
              Click on the "Open in Visual Studio Code" button. This will
              automatically launch Visual Studio Code with the repository opened
              and ready for you to start coding.
            </li>
            <li className="task-submission-item">
              A confirmation popup may appear. Simply click on "Open in Visual
              Studio Code" again to proceed.
            </li>
          </ul>
          <img
            src={Image8}
            alt="Open in Visual Studio Code"
            className="task-submission-image"
          />
        </div>
      </div>
    </div>
    </div>
     <Footer/>
     </>
  );
};

export default TaskSubmission;
