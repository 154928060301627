import React from "react";
import Header from "../../Header/Header";
import "./Homepage.css";
import Animatedlist from "../AnimatedList/Animatedlist";
import Cards from "../../Cards/Cards";
import { useState, useEffect } from "react";
import { BsInstagram } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";
import O from "../../../assets/Images/O_image.png";
import Frame3 from "../../Frame-3/Frame3";
import Frame5b from "../../Frame-5b/Frame5b";
import Frame4 from "../../Frame4/Frame4";
import Accordion from "../../Accordion/Accordion";

const Homepage = ({ device }) => {
  const [spinner, setSpinner] = useState(true);
  const [email, setEmail] = useState("");
  const [emailValid, setemailValid] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const url = "https://api.nfthing.com/nfthingSubscribe";
  const [word, setWord] = useState('DIFFERENTLY'); // Initial word
  
    useEffect(() => {
      // Set an interval to toggle the word every 2 seconds
      const interval = setInterval(() => {
        setWord(prevWord => (prevWord === 'DIFFERENTLY' ? 'TOGETHER' : 'DIFFERENTLY'));
      }, 2000);
  
      // Clean up the interval on component unmount
      return () => clearInterval(interval);
    }, []);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setEmail(value);
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
      setemailValid(true);
    } else {
      setemailValid(false);
    }
  };

  const handleSubmit = () => {
    console.log("clicked");
    setSubmitClicked(true);
    if (emailValid) {
      const requestOptions = {
        // mode: 'no-cors',
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: email,
        }),
      };
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.code == 100) {
            alert(data.message);
          } else {
            alert("Server error try again later");
          }
        });
    } else {
      alert("Enter a valid email Id");
    }
  };

  const handleNfthing = ()=>{
    window.open("https://nfthing.com/", "_blank")
  }

  const handleFormClick = () => {
    window.open("/login", "_blank");
  };

  const handleContactClick = () => {
    window.open("/form/contact", "_blank");
  };

  useEffect(() => {
    setTimeout(() => setSpinner(false), 2000);
  }, []);

  const disableScroll = () => {
    if (spinner) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  };
  useEffect(() => {
    disableScroll();
  }, [spinner]);
  const matches = device;
  if (navigator.appVersion.indexOf("Win") !== -1) {
    document.getElementsByTagName("body")[0].classList.add("win");
  }
  const platform = window.navigator.platform;
  useEffect(() => {
    if (platform === "Win32") {
      document.getElementsByClassName("footer-top-right")[0].style.top =
        "-26px";
      document.getElementsByClassName("mf2")[0].style.top = "-2.5vw";
      document.getElementsByClassName("mf3")[0].style.top = "-2.5vw";
    }
  }, []);

  const handleClick = () => {
    window.location.href = "/#join";
  };

  return (
    <>
      <div
        id="spinner"
        className="spin-container"
        style={{ display: !spinner && "none" }}
      >
        <div className="loading"></div>
      </div>
      <Header />
      <div className="main-body">
        <div className="mainframe" id="home">
          <div className="mf1">
            <h3>WELCOME TO</h3>
          </div>

          <div className="mf2">
            <p>
              web3<img src={O} alt="O" className="o-image"></img>nwards
            </p>
          </div>
          <div className="mf3">
            <h3> A decentralized movement to_ </h3>
            <h3>
              Build it{" "}
              <strong>
                <i className={`blinking`}>{word}</i>
              </strong>
            </h3>
          </div>
          <div className="mf4" onClick={handleClick}>
            <h3> JOIN US</h3>
            <h3>→</h3>
          </div>
        </div>
        <div className="frame2">
          <p>
            Welcome to web3onwards, a community dedicated to the exploration,
            education, and innovation of decentralised technologies. Our mission
            is to empower the next generation of <strong><i>Marketers, Managers, Designers and Developers</i></strong> to create a
            more open, transparent, and user-centric internet. Join us as we
            embark on this exciting journey towards a more equitable, secure,
            and democratic digital world. Together, we will shape the future of
            the internet - web3onwards!
          </p>
        </div>
        <div id="why">
          {!matches ? (
            <div className="frame3">
              <Cards />
            </div>
          ) : (
            <Frame3 />
          )}
        </div>
        {!matches ? (
          <div id="roadmap">
            <Frame5b />
          </div>
        ) : (
          <div className="frame4">
            <Frame4 />
          </div>
        )}
        <div className="faq">
            <div className="faq-title">
                <h1>
                FAQ
                </h1>
            </div>
          <Accordion />
        </div>
        <div className="frame6" id="join">
          <div className="f6-left">
            <h2 className="f6-left-title">
              I am a college/ educational institution
            </h2>
            <p className="f6-left-para">
              Join web3onwards to enhance your curriculum, empower your
              students, and cultivate a generation shaping the decentralised
              digital world, bringing power back to the people.
            </p>
            <div className="contact" onClick={handleContactClick}>             
                CONTACT US
            </div>
          </div>
          <div className="f6-right">
            <h2 className="f6-right-title">I am a student / web3 enthusiast</h2>
            <p className="f6-right-para">
              Join our thriving community of passionate students and web3
              enthusiasts. We’re on an exciting journey of learning,
              collaborating, and building the new internet together.
            </p>
            <div className="f6-join" onClick={handleFormClick}>
              <a
                href="/login"
                target="_blank"
                rel="noreferrer"
                className="f6-button"
                style={{ color: "white" }}
              >
                JOIN US
              </a>
            </div>
            <div className="f6-view">
              <a
                className="f6-button-view"
                href="/ambassadors"
                rel="noreferrer"
              >
                VIEW CAMPUS AMBASSADORS
              </a>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="footer-top">
            <div className="footer-top-left">
              <h1>ABOUT US</h1>
              <p>
                web3onwards is an initiative to create a new generation of
                community that are builders and users of their products.{" "}
              </p>
            </div>
            <div className="footer-top-mid">
              <div className="footer-join">
                <h1>JOIN NOW</h1>
                <p>Join community</p>
                <p>Contact Us</p>
              </div>
              <div className="footer-socials">
                <h1>FOLLOW US</h1>
                <a
                  href="https://www.linkedin.com/company/web3onwards/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedinIn /> LinkedIn
                </a>
                {/* <a href='https://twitter.com/nfthing_' target="_blank">
                    <BsTwitter/> Twitter
                </a> */}
                <a
                  href="https://www.instagram.com/web3onwards/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <BsInstagram /> Instagram
                </a>
              </div>
            </div>
            <div className="footer-top-right">
              <h1>web3onwards</h1>
              <div className="footer-initiative" onClick={handleNfthing}>
                <p>INITIATIVE BY NFTHING</p>
              </div>
            </div>
          </div>
          <div className="footer-mid">
            <div className="mid-container">
              <h3 style={{ minWidth: "195px" }}>
                Want more out of us? Join our newsletter
              </h3>
              <div className="email">
                <input
                  type={"email"}
                  placeholder="Enter you email address"
                  className="ip-box"
                  onChange={(e) => handleInputChange(e)}
                  value={email}
                ></input>
                <input
                  type={"submit"}
                  className="submit-button"
                  value={"SUBMIT"}
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <p>© 2022 web3onwards</p>
            <div>
              <p>PRIVACY POLICY</p>
              <p>TERMS AND CONDITIONS</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Homepage;
