import React, { useState, useEffect} from 'react';
import { useNavigate, useSearchParams  } from 'react-router-dom';
import "./SignupCustomform.css";
import CustomFormBtn from "../../../assets/Images/CustomFormBtn.svg";
import web3button from "../../../assets/Images/web3button.svg";
import coursesButton from "../../../assets/Images/coursesButton.svg";
import info from "../../../assets/Images/info.svg";
import close from "../../../assets/Images/close.svg";
import navContributors from "../../../assets/Images/nav_contri.svg";
import loginSignup from "../../../assets/Images/loginSignup.svg";

const BASE_URL = 'https://auth.web3onwards.com';

const CrsCustomform = () => {
  const [USNnumber, setUSNnumber] = useState('');
  const [college, setCollege] = useState('');
  const [searchParams] = useSearchParams();
  const [error, setError] = useState(''); 
  const navigate = useNavigate();

  const handleSubmit = async () => {
    const email = searchParams.get('email');
    if (!email) {
      alert('Email is required');
      return;
    }

    const url = `${BASE_URL}/save-custom-form`;
    const data = {
      userName: email,
      updateData: {
        USNnumber: USNnumber,
        college: college,
      }
    };

    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      if (response.ok) {
        const resJson = await response.json();
      const { userName, USNnumber, fullName, college, __v, token } = resJson.result;
      localStorage.setItem('userName', userName);
      localStorage.setItem('USN', USNnumber);
      localStorage.setItem('fullName', fullName);
      localStorage.setItem('college', college);
      localStorage.setItem('__v', __v);
      localStorage.setItem('jwtToken', resJson.token);
      navigate('/course');
      } else {
        const resJson = await response.json();
        setError(resJson.error); // Set the error message based on API response
        console.error('Failed to save the user data', resJson);
      }
    } catch (error) {
      console.error('Network error:', error);
    }
  };

  const clearError = () => {
    setError(null);
  };

  const handleUSNChange = (e) => {
    setUSNnumber(e.target.value);
    if (error) {
      clearError();
    }
  };
  
    return (
      <>
      <div className="nav-bar-container">
          <div className="page-navigation-container1">
          <div className="nav-button-container">
          <a href='/'>
          <img src={web3button} alt="web3button"/>
          </a>
          </div>
          <div className="nav-button-container">
            <a href='/course'>
          <img src={coursesButton} alt="coursesButton" />
          </a>
          </div>
          </div>
          <div className="page-navigation-container2">
          <div className="nav-button-container">
          <a href='/'>
          <img className='nav-contributors-button' src={navContributors} alt="web3button"/>
          </a>
          </div>
          <div className="nav-button-container">
            <a href='/courselogin'>
          <img className='nav-loginSignup-button'src={loginSignup} alt="coursesButton" />
          </a>
          </div>
          </div>
        </div>    
      <div className="CrsCustom-page-container">
      <div className="CrsCustom-container">
        <div className="left-box-container">
        <div className="left-box">UPSKILL<br></br>BUILD<br></br>GROW</div>
        <div className="CrsCustom-header-container">
        <div className="CrsCustom-header">Seamlessly access the world of<br></br> decentralized applications</div> 
        </div>
        </div>
        <div className="CrsCustom-right-box-container">
        <div className="CrsCustom-right-box">
        <div className="CrsCustom-content-header-container">
           <div className="CrsCustom-content-header">
            <h1 className="CrsCustom-header-text">USN Recognition</h1>
           </div>
           </div>
           <div className="CrsCustom-description-container">
           <h1 className="CrsCustom-content-description">Please enter your USN number. What's a USN- University Seat Number which is necessary to start your jouney with web3onwards.</h1>
           </div>
        <div className="CrsCustom-input-field-container">
          <input
            type="text"
            className={`CrsCustom-input-field ${error ? 'input-error' : ''}`}
            placeholder="USN number"
            value={USNnumber}
            onChange={handleUSNChange}
          />
          <input
            type="text"
            className="CrsCustom-input-field"
            placeholder="College"
            value={college}
            onChange={e => setCollege(e.target.value)}
          />
        </div>
        {error === "Please Enter Your USN Number" && (
          <div className='CrsCustom-error-message-container'>
          <div className='CrsCustom-error-outer-counter'>
          <div className="CrsCustom-error-container">
            <div className='CrsCustom-eror-header-container'>
                <img className='CrsCustom-info-icon' src={info} alt='info'/>
                <h3 className='CrsCustom-error-header'>USN number Required</h3>
            </div>
            <div className='CrsCustom-error-content-container'>
              <h3 className='CrsCustom-error-content'>Please Enter your USN number</h3>
            </div>
          </div>
          <div className='CrsCustom-x-icon-container'>
            <img className='CrsCustom-x-icon' src={close} alt='x-icon' onClick={clearError}/>
          </div>
          </div>
          </div>
          )}
          {error === "User Already Exists in DB" && (
            <div className='CrsCustom-error-message-container'>
            <div className='CrsCustom-error-outer-counter'>
            <div className="CrsCustom-error-container">
              <div className='CrsCustom-eror-header-container'>
                  <img className='CrsCustom-info-icon' src={info} alt='info'/>
                  <h3 className='CrsCustom-error-header'>This USN number is already registered</h3>
              </div>
              <div className='CrsCustom-error-content-container'>
                <h3 className='CrsCustom-error-content'>User already exists, Please Login if you already registered</h3>
              </div>
            </div>
            <div className='CrsCustom-x-icon-container'>
              <img className='CrsCustom-x-icon' src={close} alt='x-icon' onClick={clearError}/>
            </div>
            </div>
            </div>
          )}
        <div className="CrsCustom-box">
          <img  className='CrsCustom-button' src={CustomFormBtn} alt="CustomFormBtn" onClick={handleSubmit}/>
        </div>
        </div>
        </div>
      </div>
      </div>
      </>
    );
  };
 
  export default CrsCustomform;