import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BsTwitter, BsInstagram } from "react-icons/bs";
import "./ContactForm.css";

// import "./RegistrationForm.css";

function ContactForm() {
  const url = "https://api.nfthing.com/contactus";
  // const url = "http://localhost:5000/contactus";
  const [submitClicked, setSubmitClicked] = useState(false);
  const [Name, setName] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [newsletter, setNewsletter] = useState(false);
  const [uniName , setUniName] = useState("");
  const [website, setWebsite] = useState("");
  const [profession, setProfession]= useState("")
  const [dept, setDept] = useState("")
  const [uniNameValid, setUniNameValid]=useState(false)
  const [nameValid, setnameValid] = useState(false);
  const [emailValid, setemailValid] = useState(false);
  const [phoneValid, setphonevalid] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (id === "Name") {
      setName(value);
      if (value.length > 1) {
        setnameValid(true);
      } else {
        setnameValid(false);
      }
    }
    if (id === "phoneNumber") {
      setphoneNumber(value);
      if (value.length >= 10) {
        setphonevalid(true);
      } else {
        setphonevalid(false);
      }
    }
    if (id === "email") {
      setEmail(value);
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        setemailValid(true);
      } else {
        setemailValid(false);
      }
    }
    if (id === "UniName") {
      setUniName(value);
      if (value.length > 1) {
        setUniNameValid(true);
      } else {
        setUniNameValid(false);
      }
    }
    if(id==="profession"){
      setProfession(value)
    }

    if(id==="website"){
      setWebsite(value)
    }

    if(id==="dept"){
      setDept(value)
    }

    if (id === "newsletter") {
      setNewsletter(e.target.checked);
    }
  };

  const validateForm = () => {
    setSubmitClicked(true);
    if (email ==="") {
      setemailValid(false);
    }
    if (Name === "") {
      setnameValid(false);
    }
    if (email === "" && Name === "" && phoneNumber === "") {
      console.log("enter fields");
    } else {
      handleSubmit();
    }
  };

  useEffect(() => console.log(newsletter), [newsletter]);

  const handleSubmit = () => {
    console.log(nameValid, emailValid);
    if (nameValid && emailValid && uniNameValid) {
      sendData();
    } else {
      console.log("check input fields");
    }
  };

  const sendData = () => {
    // Simple POST request with a JSON body using fetch
    const requestOptions = {
      // mode: 'no-cors',
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: Name,
        phone: phoneNumber,
        email: email,
        university: uniName,
        website:website,
        profession:profession,
        department:dept,
        newsletter: newsletter
      }),
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => responses(data));
  };

  const responses = (data) => {
    console.log(data);
    navigate("/success");
    if (data.code === 100||data.code===204) {
      navigate("/form/success");
      localStorage.setItem("isAllowed",true)
    } else{
      navigate("/error");
    }
  };

  return (
    <>
      <div className="form" style={{width:"100%"}}>
        <div className="form-body-contact">
          <div className="username">
            <input
              className="form__input"
              type="text"
              value={Name}
              onChange={(e) => handleInputChange(e)}
              style={
                submitClicked && !nameValid
                  ? { borderBottom: "0.5px solid red" }
                  : {}
              }
              id="Name"
              placeholder="Enter your name*"
              required
            />
            <input
              type="tel"
              name=""
              id="phoneNumber"
              value={phoneNumber}
              className="form__input"
              onChange={(e) => handleInputChange(e)}
              style={
                submitClicked && !phoneValid
                  ? { borderBottom: "0.5px solid red" }
                  : {}
              }
              placeholder="Phone Number*"
              required
            />
          </div>
          <div className="error">
          <p
            style={submitClicked && !nameValid ? { color: "red", marginRight:"39%" } : {marginRight:"39%"}}
          >
            Enter your name
          </p>
          <p           
            style={submitClicked && !phoneValid ? { color: "red" } : {}}
          >
            Enter your Phone Number
          </p>
          </div>
          <div className="email">
            <input
              type="email"
              id="email"
              className="form__input"
              value={email}
              onChange={(e) => handleInputChange(e)}
              style={
                submitClicked && !emailValid
                  ? { borderBottom: "0.5px solid red" }
                  : {}
              }
              placeholder="Email Address*"
              required
            />
          </div>
          <p
            className="error"
            style={submitClicked && !emailValid ? { color: "red" } : {}}
          >
            Enter a valid email id
          </p>
          <div className="unideets">
            <input
              type="text"
              name=""
              id="UniName"
              value={uniName}
              className="form__input"
              onChange={(e) => handleInputChange(e)}
              placeholder="University*"
              required
            />
            <input
              type="text"
              name=""
              id="website"
              value={website}
              className="form__input"
              onChange={(e) => handleInputChange(e)}
              placeholder="University website"
              required
            />
          </div>
          <p
            className="error"
            style={submitClicked && !uniNameValid ? { color: "red" } : {}}
          >
            Enter a University name
          </p>
          
          <div className="profession">
            <input
              className="form__input"
              type="text"
              id="profession"
              value={profession}
              onChange={(e) => handleInputChange(e)}
              placeholder="Profession"
              // style={{ marginBottom: "10px" }}
            />
            <input
              type="txet"
              name=""
              id="dept"
              value={dept}
              className="form__input"
              onChange={(e) => handleInputChange(e)}
              placeholder="Department"
              required
            />
          </div>
          <div className="newsletters">
            {/* <label className="form__label" for="newsletter">
              Newsletter{" "}
            </label> */}
            <input
              className="checkbox1"
              type="checkbox"
              value={true}
              id="newsletter"
              onChange={(e) => handleInputChange(e)}
              placeholder="newsletter"
            />
            <label for="checkbox" className="form__label">
              {/* Receive updates about NFThing on your email through our newsletter */}
              Get the latest news in web3 and our job postings in your inbox
              every month
            </label>
          </div>
        </div>
        <div class="form-footer">
          <div class="linecon"></div>

          <div class="sq sl"></div>
          <div class="sq sr"></div>

          <button type="submit" className="form-btn" onClick={() => validateForm()}>
            SUBMIT
          </button>
        </div>
        <div className="socials mob">
          <div class="gentext">
            
            //GENERATING <br></br> SOCIAL LINKS FROM INDEX
          </div>
          <h2>FOLLOW US ON</h2>
          <div className="links">
            <a href="https://twitter.com/NFThing_" target="_blank" rel="noreferrer">
              <BsTwitter className="icon" /> <p>TWITTER</p>
            </a>
            <a
              href="https://instagram.com/nfthing_?igshid=YmMyMTA2M2Y="
              target="_blank"
              rel="noreferrer"
            >
              <BsInstagram className="icon" /> <p>INSTAGRAM</p>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactForm;
// Git temp update
