
import React from 'react'
import "./Cards.css"


const Cards = () => {
  return (
    <>
    <p className='question'>WHY WEB3ONWARDS? WE'RE GLAD YOU ASKED</p>
    <div className="slider">
  <div className='mob-card'><p><strong>BUILD</strong> the new internet together</p></div>
  <div className='mob-card'><p><strong>INCUBATE</strong> your ideas from POC to startups</p></div>
  <div className='mob-card'><p><strong>UPSKILL</strong>your web3 and blockchain knowledge/skills</p></div>
  <div className='mob-card'><p>Be part of a community of <strong> LIKE MINDED INDIVIDUALS</strong></p></div>
  <div className='mob-card'><p><strong>CONTRIBUTE</strong> to opensource web3 projects</p></div>
</div>

    </>
  )
}

export default Cards