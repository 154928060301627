// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
  display: flex;
  justify-content: space-between;
  margin-top: 6.3vh;
  width: 85%;
}
.rit {
  height: 12vh;
  margin-top: -3.5vh;
  max-height: 130px;
}
.nfthing {
  height: 6vh;
}

.vector {
  width: 28px;
  height: 43px;
}

.header-hr {
  margin: 0;
  transform: skew(-20deg);
  height: 42px;
  margin-top: 40px;
}

@media only screen and (max-width: 600px) {
  .navbar {
    
    margin-top: 4.125vh;
  }
  .nfthing {
    
    /* width: 80px; */
    height: 3vh;
  }

  .rit {
    
    height: 7vh;
    margin-top: -1.5vh;
    
  }

  .vector {
    
    width: 14px;
    height: 23px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Fom/components/header.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,iBAAiB;EACjB,UAAU;AACZ;AACA;EACE,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;AACnB;AACA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,SAAS;EACT,uBAAuB;EACvB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE;;IAEE,mBAAmB;EACrB;EACA;;IAEE,iBAAiB;IACjB,WAAW;EACb;;EAEA;;IAEE,WAAW;IACX,kBAAkB;;EAEpB;;EAEA;;IAEE,WAAW;IACX,YAAY;EACd;AACF","sourcesContent":[".navbar {\n  display: flex;\n  justify-content: space-between;\n  margin-top: 6.3vh;\n  width: 85%;\n}\n.rit {\n  height: 12vh;\n  margin-top: -3.5vh;\n  max-height: 130px;\n}\n.nfthing {\n  height: 6vh;\n}\n\n.vector {\n  width: 28px;\n  height: 43px;\n}\n\n.header-hr {\n  margin: 0;\n  transform: skew(-20deg);\n  height: 42px;\n  margin-top: 40px;\n}\n\n@media only screen and (max-width: 600px) {\n  .navbar {\n    \n    margin-top: 4.125vh;\n  }\n  .nfthing {\n    \n    /* width: 80px; */\n    height: 3vh;\n  }\n\n  .rit {\n    \n    height: 7vh;\n    margin-top: -1.5vh;\n    \n  }\n\n  .vector {\n    \n    width: 14px;\n    height: 23px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
