import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Crsignup.css";
import signupButton from "../../../assets/Images/signupbutton.svg";
import web3button from "../../../assets/Images/web3button.svg";
import coursesButton from "../../../assets/Images/coursesButton.svg";
import navContributors from "../../../assets/Images/nav_contri.svg";
import loginSignup from "../../../assets/Images/loginSignup.svg";
import info from "../../../assets/Images/info.svg";
import close from "../../../assets/Images/close.svg";
import googleAuth from "../../../assets/Images/google.svg";

const CrsignupPage = () => {
  const [fullName, setFullName] = useState("");
  const [college, setcollege] = useState("");
  const [USNnumber, setUSN] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [USNError, setUSNError] = useState(false);
  const [USNErrorMessage, setUSNErrorMessage] = useState("");
  const [userNameError, setUserNameError] = useState(false);
  const [userNameErrorMessage, setUserNameErrorMessage] = useState("");
  const [isGoogleAuthDisabled, setIsGoogleAuthDisabled] = useState(true);
  const [inputError, setInputError] = useState(false);

  const navigate = useNavigate();

  const handleGoogleAuth = () => {
    clearError();
    window.open(`https://auth.web3onwards.com/auth/google`, "_self");
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = regex.test(email);
    setIsEmailValid(isValid);
    setEmailError(!isValid);
  };

  const validatePassword = (password) => {
    const hasInvalidChars = /[<,.# ]/.test(password);
    const isValid = !hasInvalidChars;
    setIsPasswordValid(isValid);
    setPasswordError(!isValid);
  };

  const handleEmailChange = (e) => {
    setUserName(e.target.value);
    if (userNameError) {
      setUserNameError(false);
      setUserNameErrorMessage("");
    }
    //validateEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    validatePassword(e.target.value);
  };
  const handleEmailBlur = (e) => {
    validateEmail(e.target.value);
  };

  const handleSignupClick = () => {
    // if (!fullName || !college || !USNnumber || !userName || !password) {
    //   setInputError(true);
    //  // return;
    // }

    if (!isEmailValid || !isPasswordValid) {
      console.error("Please enter a valid email address.");
      return;
    }
    const payload = {
      fullName,
      college,
      USNnumber,
      userName,
      password,
    };

    fetch("https://auth.web3onwards.com/signup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          response.json().then((data) => {
            if (data.error === "YOUR ACCOUNT ALREADY EXISTS") {
              setUserNameError(true);
              setUserNameErrorMessage(data.error);
            } else if (data.error === "THE USN ALREADY EXISTS") {
              setUSNError(true);
              setUSNErrorMessage(data.error);
            }
          });
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("response data from form signup",data);
        localStorage.setItem("userName", data.user.useruserName);
        localStorage.setItem("USN", data.user.userUSNnumber);
        localStorage.setItem("fullName", data.user.user.fullName);
        localStorage.setItem("college", data.user.user.college);
        localStorage.setItem("__v", data.user.user__v);
        localStorage.setItem("jwtToken", data.token);
        navigate("/courselogin");
      })
      .catch((error) => {
        console.error("Signup failed: " + error.message);
      });
  };

  const handleUSNChange = (e) => {
    setUSN(e.target.value);
    console.log("USN error on changed USN ", USNError);
    if (USNError) {
      setUSNError(false);
      setUSNErrorMessage("");
    }
  };

  const clearError = () => {
    setEmailError(false);
    setPasswordError(false);
    console.log("USN error in clearError before setting to false ", USNError);
    setUSNError(false);
    setUSNErrorMessage("");
    setUserNameError(false);
    setUserNameErrorMessage("");
    console.log("USN error in clearError after setting to false ", USNError);
  };

  return (
    <>
      <div className="signup-page-container">
        <div className="nav-bar-container">
          <div className="page-navigation-container1">
            <div className="nav-button-container">
              <a href="/">
                <img src={web3button} alt="web3button" />
              </a>
            </div>
            <div className="nav-button-container">
              <a href="/course">
                <img src={coursesButton} alt="coursesButton" />
              </a>
            </div>
          </div>
          <div className="page-navigation-container2">
            <div className="nav-button-container">
              <a href="/">
                <img
                  className="nav-contributors-button"
                  src={navContributors}
                  alt="web3button"
                />
              </a>
            </div>
            <div className="nav-button-container">
              <a href="/courselogin">
                <img
                  className="nav-loginSignup-button"
                  src={loginSignup}
                  alt="coursesButton"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="signup-container">
          <div className="left-box-container">
            <div className="left-box">
            UPSKILL <br></br>BUILD<br></br> GROW
            </div>
            <div className="signup-header-container">
              <div className="signup-header">
                Seamlessly access the world of decentralized applications
              </div>
            </div>
          </div>
          <div className="signup-right-box-container">
            <div className="signup-right-box">
              <div
                className="google-auth-outer-container"
                onClick={handleGoogleAuth}
              >
                <div className="google-auth-button">
                  <img src={googleAuth} />
                  Continue with Google
                </div>
              </div>
              <div className="google-auth-bottom-outer-container">
                <div className="auth-vector-container"></div>
                <div className="google-auth-bottom-container">
                  <h3 className="google-auth-bottom">or</h3>
                </div>
                <div className="auth-vector-container"></div>
              </div>
              <div className="input-field-container">
                <input
                  type="text"
                  className="input-field"
                  placeholder="Full Name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
                <input
                  type="text"
                  className="input-field"
                  placeholder="College Name"
                  value={college}
                  onChange={(e) => setcollege(e.target.value)}
                />
                <input
                  type="text"
                  className={`input-field ${USNError ? "input-error" : ""}`}
                  placeholder="USN"
                  value={USNnumber}
                  onChange={handleUSNChange}
                />
                <input
                  type="email"
                  className={`input-field ${emailError || userNameError ? "input-error" : ""
                    }`}
                  placeholder="Email address"
                  value={userName}
                  onChange={handleEmailChange}
                  onBlur={handleEmailBlur}
                />
                <input
                  type="password"
                  className={`input-field ${passwordError ? "input-error" : ""
                    }`}
                  placeholder="Password"
                  value={password}
                  onChange={handlePasswordChange}
                />
              </div>
              <div className="signup-box">
                {emailError && (
                  <div className="crs-error-message-container">
                    <div className="crs-error-outer-counter">
                      <div className="crs-error-container">
                        <div className="crs-eror-header-container">
                          <img
                            className="crs-info-icon"
                            src={info}
                            alt="info"
                          />
                          <h3 className="crs-error-header">
                            Email is not Valid
                          </h3>
                        </div>
                        <div className="crs-error-content-container">
                          <h3 className="crs-error-content">
                            Please check your email address again.
                          </h3>
                        </div>
                      </div>
                      <div className="crs-x-icon-container">
                        <img
                          className="crs-x-icon"
                          src={close}
                          alt="x-icon"
                          onClick={clearError}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {passwordError && (
                  <div className="crs-error-message-container">
                    <div className="crs-error-outer-counter">
                      <div className="crs-error-container">
                        <div className="crs-eror-header-container">
                          <img
                            className="crs-info-icon"
                            src={info}
                            alt="info"
                          />
                          <h3 className="crs-error-header">
                            Create a different Password
                          </h3>
                        </div>
                        <div className="crs-error-content-container">
                          <h3 className="crs-error-content">
                            ,.# and Spaces please exclude these characters and
                            create a strong password
                          </h3>
                        </div>
                      </div>
                      <div className="crs-x-icon-container">
                        <img
                          className="crs-x-icon"
                          src={close}
                          alt="x-icon"
                          onClick={clearError}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {userNameError && (
                  <div className="crs-error-message-container">
                    <div className="crs-error-outer-counter">
                      <div className="crs-error-container">
                        <div className="crs-eror-header-container">
                          <img
                            className="crs-info-icon"
                            src={info}
                            alt="info"
                          />
                          <h3 className="crs-error-header">
                            This Email is already taken
                          </h3>
                        </div>
                        <div className="crs-error-content-container">
                          <h3 className="crs-error-content">
                            Please choose a different Email or login if you
                            already have an account
                          </h3>
                        </div>
                      </div>
                      <div className="crs-x-icon-container">
                        <img
                          className="crs-x-icon"
                          src={close}
                          alt="x-icon"
                          onClick={clearError}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {USNError && (
                  <div className="crs-error-message-container">
                    <div className="crs-error-outer-counter">
                      <div className="crs-error-container">
                        <div className="crs-eror-header-container">
                          <img
                            className="crs-info-icon"
                            src={info}
                            alt="info"
                          />
                          <h3 className="crs-error-header">
                            This USN number is already registered
                          </h3>
                        </div>
                        <div className="crs-error-content-container">
                          <h3 className="crs-error-content">
                            User already exists, Please Login if you already
                            registered
                          </h3>
                        </div>
                      </div>
                      <div className="crs-x-icon-container">
                        <img
                          className="crs-x-icon"
                          src={close}
                          alt="x-icon"
                          onClick={clearError}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {/* {inputError && (
         <div className='crs-error-message-container'>
         <div className='crs-error-outer-counter'>
         <div className="crs-error-container">
           <div className='crs-eror-header-container'>
             <img className='crs-info-icon' src={info} alt='info'/>
             <h3 className='crs-error-header'>All fields are required</h3>
           </div>
           <div className='crs-error-content-container'>
             <h3 className='crs-error-content'>Please fill in the empty field</h3>
           </div>
         </div>
         <div className='crs-x-icon-container'>
         <img className='crs-x-icon' src={close} alt='x-icon' onClick={clearError}/>
         </div>
         </div>
         </div>
                )} */}
                <img
                  src={signupButton}
                  alt="Signup"
                  onClick={handleSignupClick}
                />
              </div>
              <div className="signup-link-container">
                <div className="signup-link">
                  Already have an account? <a href="/courselogin">Login here</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CrsignupPage;
