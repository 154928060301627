import React from "react";
import "./MintCertificateBox.css";
import MintCertImg from "../../../../assets/Images/MintCertificateimg.png";
import MintCertBox from "../../../../assets/Images/mint_cert_box.png"
const MintCertificateBox = () => {
  return (
    <>
      <div className="mint-box-2">
        <div className="box-2-mint-inner-box">
          <div className="box-2-mint-inner-left-box">
            <div className="">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat
              enim facilis doloremque id pariatur, illo explicabo reiciendis
              accusantium dolore unde provident soluta aspernatur? Nulla
              deserunt omnis dignissimos obcaecati expedita in quos laborum iste
              cupiditate earum tempora id exercitationem, ratione
              necessitatibus. Quam officiis mollitia beatae ratione reiciendis
              expedita eaque iusto qui? Lorem ipsum, dolor sit amet consectetur
              adipisicing elit. Repellat <br /> cumque molestiae corporis vero
              temporibus? Veniam quis cum repudiandae pariatur amet et voluptate
              ex cupiditate explicabo quod, quo perspiciatis obcaecati
              assumenda, nam deleniti maiores officia exercitationem, nobis
              debitis necessitatibus iste ullam eaque? Unde at nostrum et optio
              debitis! Ullam, porro optio? Lorem, ipsum dolor sit amet
              consectetur adipisicing elit. Ab labore earum facilis tempore sed?
              Placeat odit eaque accusamus. Perspiciatis cum nobis tenetur
              voluptate vitae sunt distinctio consectetur aut perferendis
              dolorem!
            </div>
            <div className="mint-cert-box">
              <img src={MintCertBox} alt="" />
            </div>
          </div>
          <div className="box-2-inner-right-box">
            <img src={MintCertImg} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default MintCertificateBox;
