import logo from "./logo.svg";
import "./App.css";
import { useState, useEffect } from "react";
import Homepage from "./components/Pages/HomePage/Homepage";
import CaPage from "./components/Pages/CaPage/CaPage";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import FormPage from "./Fom/components/FormPage/FormPage";
import ContactPage from "./Fom/components/ContactPage/ContactPage";
import ErrorPage from "./Fom/components/ErrorPahe/ErrorPage";
import Success from "./Fom/components/SuccessPage/Success";
import CoursePage from "./components/Pages/Course/CoursePage";
import CourseDescription from "./components/Pages/CourseDescription/CourseDescription";
import LoginPage from './components/Pages/CourseLogin/Login';
import CrsignupPage from './components/Pages/CourseLogin/Crsignup';
import CrsResetPassword from "./components/Pages/CourseLogin/CrsResetPwd";
import CrsCustomform from "./components/Pages/CourseLogin/SignupCustomform";
import Crsoather from "./components/Pages/CourseLogin/Crsoath";
import TaskSubmission from "./components/Pages/Guidelines/TaskSubmission";


function App() {
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 500px)").matches
  );

  useEffect(() => {
    console.log("useEffect ran at app.js");
    localStorage.setItem("isAllowed", false);
    window
      .matchMedia("(min-width: 500px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);
  return (
    <div className="App">
      <Router>
        <>
          <Routes>
            <Route path="/" element={<Homepage device={matches} />} />
            <Route path="/ambassadors" element={<CaPage device={matches} />} />
            {/* <Route path="form/1bybmsit182" element={<FormPage login={false}/>} /> */}
            <Route path="/login" element={<FormPage login={false} />} />
            <Route path="/form" element={<FormPage login={true} />} />
            <Route path="form/contact" element={<ContactPage />} />
            <Route path="/course" element={<CoursePage/>} />
            <Route path="/help" element={<TaskSubmission/>} />
            <Route path="/courselogin" element={<LoginPage/>} />
            <Route path="/Redirection" element={<Crsoather/>} />
            <Route path="/coursesignup" element={<CrsignupPage/>} />
            <Route path="/signupCustom" element={<CrsCustomform/>} />
            <Route path="/course-description" element={<CourseDescription />} />
            <Route path="/course-resetPwd" element={<CrsResetPassword/>} />
            <Route path="form/success" element={<Success />} />
            <Route path="/*" element={<ErrorPage />} />
          </Routes>
        </>
      </Router>
    </div>
  );
}

export default App;
