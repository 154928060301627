/* React Component Version */
import React from "react";
import { BsDiscord } from "react-icons/bs";
import "./discordButton.css"
import ReactDOM from "react-dom";

const DiscordButton = (props) => (
  <a className="discord-btn"
  style={{
    borderRadius: 8,}}
    href={props.link}
  >
    <div style={{ width: 25, height: 25, marginRight: 15 }}>
      <BsDiscord size="27 px"/>
    </div>
    <span>Login with Discord</span>
  </a>
);

export default DiscordButton;