import React, { useState } from 'react'
import close from "../../assets/Images/closebtn.svg"
import NFT from "../../assets/Images/NFT.png"
import NFT2 from "../../assets/Images/NFT2.svg"
import "./CaPopupMob.css"

const CaPopupMob = ({closeSwitch,data}) => {
  const [menu, setMenu]=useState(true)
  const linkedinClick = ()=>{
    window.open(data.linkedin, "_blank")
}

  const changeMenu=()=>{
    setMenu(!menu)
  }
  return (
    <div className='mobile-pop'>
        <div className='mpop-top'style={{backgroundColor:data.uni==="BMS INSTITUTE OF TECHNOLOGY"?"#DECCE5":"#D2ECFF"}} >
            <div>
                <div  className="mpop-close"><img onClick={closeSwitch} src={close}/></div>
                <div className='mpop-menu'>
                    <div style={{borderBottom:menu?"1pt solid black":"none"}} onClick={changeMenu}><p>About {data.firstname}</p></div>
                    <div style={{borderBottom:menu?"none":"1pt solid black"}} onClick={changeMenu}><p>{data.firstname}'s NFT</p></div>
                </div>
                <div className='mpop-nft'>
                    <img src={data.uni==="BMS INSTITUTE OF TECHNOLOGY"?NFT:NFT2}/>
                </div>
                <div className='mpop-name'>
                    <p>{menu?data.name:"w3o gen 1"} </p>
                </div>
            <div>
            </div>  
            </div>
        </div>
        <div className='mpop-bottom'>
            
            <div className='mpop-details'>
                <h3 className='mpop-desc-title'>
                    {menu?"UNIVERSITY":""}
                </h3>
                <h3 className='mpop-descriptions'>   
                    {menu?data.uni:""}
                </h3>
            </div>
            <div className='mpop-details'>
                <h3 className='mpop-desc-title'>
                {menu?"LOCATION":""}
                </h3>
                <h3 className='mpop-descriptions'>
                    {menu?data.location:""}
                </h3>
            </div>
            <div className='mpop-details'>
                <h3 className='mpop-desc-title'>
                    {menu?"COURSE":""}
                </h3>
                <h3 className='mpop-descriptions'>
                    {menu?data.course:""}
                </h3>
            </div>
            <h1 style={{display: menu?"none":"block"}}> COMING SOON</h1>
            <div className='mpop-btn' style={{display: menu?"block":"none"}} onClick={linkedinClick}>
                <p>View on {"LinkedIn"}</p>
            </div>
        </div>
    </div>
  )
}

export default CaPopupMob