import "./Header.css"
import { useLocation} from 'react-router-dom';
import React, { useEffect, useState } from 'react'

const Header = () => {
  const [underline, setUnderline] = useState(false)
   const handleClick = ()=>{
    window.location.href="/#join"
   }

  if (navigator.appVersion.indexOf("Win") !== -1){
    document.getElementsByTagName("body")[0].classList.add("win");  
  }
  const platform = window.navigator.platform;
  const route = useLocation()
  useEffect(() => {

    if(route.pathname==="/ambassadors"){
      console.log((route.pathname))
      setUnderline(true)
      console.log(underline)
    }
    if (platform === "Win32") {
      document.getElementsByClassName("join-p")[0].style.paddingTop = "16px";
      // document.getElementsByClassName("join-p")[1].style.margin = "2px";
    }
  }, []);
  const menuToggle=()=>{
    console.log("toggle")
    const hamburger = document.querySelector(".hamburger")
    const navMenu = document.querySelector(".nav-menu")
    hamburger.classList.toggle("active")
    navMenu.classList.toggle("active")
    
  }

  return (
    <header>
        <a className="title" href="/">web3onwards</a>
        <div className="header-right">
          <div className="abcd">
            <div className="wxyz">
            <a className="ambassadors" href="/course">
               <strong>COURSE</strong> 
            </a></div>
            <div className="border"  style={{display: underline?"block":"none"}}></div>
            </div>
            <div className="join-button" onClick={handleClick}>
               <a href="#join" className="join-p"> JOIN US &nbsp; &nbsp; &nbsp; →</a>
            </div>   
        </div>  
        <div className="ham-menu">
            <div className="hamburger" onClick={menuToggle}>
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="bar"></span>
            </div>
            <ul className="nav-menu">
              <li className="nav-item">
                <a href="/#home" onClick={menuToggle}>HOME</a>
              </li>
              <li className="nav-item" onClick={menuToggle}>
                <a href="/#why">WHY W3O</a>
              </li>
              <li className="nav-item">
                <a href="/#roadmap" onClick={menuToggle}>ROADMAP</a>
              </li>
              <li className="nav-item">
                <a href="/ambassadors" onClick={menuToggle}>AMBASSADORS</a>
              </li>
              <li className="nav-item" onClick={menuToggle} >
                <a href="/#join">JOIN US</a>
              </li>
            </ul>
            
            </div>
    </header>
  )
}

export default Header