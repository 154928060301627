import React from 'react'
import { useState, useEffect } from 'react'
import "./CaCard.css"
import NFT from "../../assets/Images/NFT.png"
import NFT2 from "../../assets/Images/NFT2.svg"
import CApopUp from '../CApopUp/CApopUp'
import close from "../../assets/Images/closebtn.svg"
import CaPopupMob from '../CaPopupMob/CaPopupMob'
import CabgBlue from "../../assets/Images/CAbg-blue.svg"
import CAbg from "../../assets/Images/CA_bg.png"

const CaCard = ({data, device}) => {
  const [popup, setPopup]=useState(false)
  const popupSwitch=()=>{
    setPopup(!popup)
}
const disableScroll = () => {
  if (popup&&device) {
    document.body.style.overflowY = "hidden";
  } else {
    document.body.style.overflowY = "auto";
   
  }
};

useEffect(() => {
  disableScroll();
}, [popup]);
  return (
    <>{device?
     <div className='popup' style={{"display":popup?"flex":"none"}}>
      <div onClick={popupSwitch} className="pop-close"><img src={close}/></div>
      <CApopUp data={data} />
    </div>:
    <div className='popup' style={{"display":popup?"flex":"none"}}>
      
    < CaPopupMob data={data} closeSwitch={popupSwitch}/></div>
    }
    <div class="card" style={{backgroundImage:data.uni==="BMS INSTITUTE OF TECHNOLOGY"?`url(${CAbg})`:`url(${CabgBlue})`}}>  
        <h3>{data.firstname} {data.lastname}</h3>
        <img className='card-image' src={data.uni==="BMS INSTITUTE OF TECHNOLOGY"?NFT:NFT2}></img> 
        <div className='container'>
          
        </div>
        <button className='btn' onClick={popupSwitch}></button>
    </div>
    </>
  )
}

export default CaCard