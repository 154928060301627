// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/fonts/N27-RegularItalic.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: N27;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  }
  
.discord-btn{
    color: white;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    padding: 10px 10px;
    font-size: 18px;
    font-family: N27;
    justify-content: space-around;
    background-color: #7589da;
    text-decoration: none;
    border-radius: 9;
    width: 16vw;
    height: 4vh;

}

@media only screen and (max-width: 600px){
    .discord-btn{
        width: 30vw;
        font-size: 12px;
        padding: 3px 3px;
        /* margin-right: 8vw; */
    }
}`, "",{"version":3,"sources":["webpack://./src/Fom/components/DiscordButton/discordButton.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,4CAAoD;EACtD;;AAEF;IACI,YAAY;IACZ,iBAAiB;IACjB,oBAAoB;IACpB,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,6BAA6B;IAC7B,yBAAyB;IACzB,qBAAqB;IACrB,gBAAgB;IAChB,WAAW;IACX,WAAW;;AAEf;;AAEA;IACI;QACI,WAAW;QACX,eAAe;QACf,gBAAgB;QAChB,uBAAuB;IAC3B;AACJ","sourcesContent":["@font-face {\n    font-family: N27;\n    src: url(\"../../assets/fonts/N27-RegularItalic.otf\");\n  }\n  \n.discord-btn{\n    color: white;\n    font-weight: bold;\n    display: inline-flex;\n    align-items: center;\n    padding: 10px 10px;\n    font-size: 18px;\n    font-family: N27;\n    justify-content: space-around;\n    background-color: #7589da;\n    text-decoration: none;\n    border-radius: 9;\n    width: 16vw;\n    height: 4vh;\n\n}\n\n@media only screen and (max-width: 600px){\n    .discord-btn{\n        width: 30vw;\n        font-size: 12px;\n        padding: 3px 3px;\n        /* margin-right: 8vw; */\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
