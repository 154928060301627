import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import loginButton from "../../../assets/Images/loginButton.svg";
import web3button from "../../../assets/Images/web3button.svg";
import coursesButton from "../../../assets/Images/coursesButton.svg";
import info from "../../../assets/Images/info.svg";
import close from "../../../assets/Images/close.svg";
import navContributors from "../../../assets/Images/nav_contri.svg";
import loginSignup from "../../../assets/Images/loginSignup.svg";
import resetpwdbtn1 from "../../../assets/Images/ResetPwdBtn1.svg";
import googleAuth from "../../../assets/Images/google.svg";

const BASE_URL = "https://auth.web3onwards.com";

const LoginPage = () => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [errorType, setErrorType] = useState(null);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [registeredEmail, setRegisteredEmail] = useState("");
  const [resetError, setResetError] = useState("");
  const [networkError, setNetworkError] = useState("");
  const [isGoogleAuthDisabled, setIsGoogleAuthDisabled] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const jwtToken = localStorage.getItem("jwtToken");
    const USN = localStorage.getItem("USN");

    if (jwtToken && USN) {
      navigate("/course");
    }
  }, [navigate]);

  const handleGoogleLogin = () => {
    console.log("clicked");
    clearError();
    window.open(`https://auth.web3onwards.com/auth/google`, "_self");
  };

  // useEffect(() => {
  //   const handleAuthResponse = () => {
  //     const params = new URLSearchParams(window.location.search);
  //     const token = params.get("JWT"); // Ensure this matches the key used in your redirect URL
  //     if (token) {
  //       localStorage.setItem("jwtToken", token);
  //       navigate("/course");
  //       console.log(localStorage.getItem("jwtToken"));
  //     }
  //   };

  //   handleAuthResponse();
  // }, []);

  const handleLoginClick = () => {
    const payload = {
      userName,
      password,
    };
    fetch(`${BASE_URL}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          setError(data.error); // Set error message
          setErrorType(data.error); // Set error type for conditional rendering
        } else {
          const jwtToken = data.token;
          localStorage.setItem("jwtToken", data.token);
          fetch(`${BASE_URL}/getuseridwithjwt?token=${data.token}`)
            .then((response) => response.json())
            .then((userData) => {
              const userInfo = userData.USNnumber;
              localStorage.setItem("USN", userInfo.USNnumber);
              localStorage.setItem("userName", userInfo.userName);
              localStorage.setItem("fullName", userInfo.fullName);
              localStorage.setItem("college", userInfo.college);
              localStorage.setItem("__v", userInfo.__v);
              navigate("/course");
            })
            .catch((error) => {
              setError("Failed to fetch user USN: " + error.message);
              setErrorType(null); // Clear error type when there's another error
            });
        }
      })
      .catch((error) => {
        setError("Login failed: " + error.message);
        setErrorType(null); // Clear error type when there's another error
      });
  };

  const handleResetPassword = () => {
    fetch(`${BASE_URL}/forgot-password`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ userName: registeredEmail }),
    })
      .then((response) => {
        if (response.status === 200) {
          navigate("/course-resetPwd");
        } else if (response.status === 404) {
          setResetError("User not found. Please check the entered email.");
        } else if (response.status === 500) {
          setNetworkError("Failed to reset password. Please try again later.");
        } else {
          setNetworkError("An unexpected error occurred. Please try again.");
        }
      })
      .catch((error) => {
        setNetworkError("An unexpected error occurred. Please try again.");
      });
  };

  const clearError = () => {
    setError(null);
    setErrorType(null);
    setResetError("");
    setNetworkError("");
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (errorType) {
      clearError();
    }
  };
  const handleUsernameChange = (e) => {
    setUserName(e.target.value);
    if (errorType) {
      clearError();
    }
  };

  const toggleResetPassword = () => {
    setShowResetPassword((prev) => !prev); // Toggles the display
    setResetError("");
    setNetworkError("");
  };

  return (
    <>
      <div className="nav-bar-container">
        <div className="page-navigation-container1">
          <div className="nav-button-container">
            <a href="/">
              <img src={web3button} alt="web3button" />
            </a>
          </div>
          <div className="nav-button-container">
            <a href="/course">
              <img src={coursesButton} alt="coursesButton" />
            </a>
          </div>
        </div>
        <div className="page-navigation-container2">
          <div className="nav-button-container">
            <a href="/">
              <img
                className="nav-contributors-button"
                src={navContributors}
                alt="web3button"
              />
            </a>
          </div>
          <div className="nav-button-container">
            <a href="/courselogin">
              <img
                className="nav-loginSignup-button"
                src={loginSignup}
                alt="coursesButton"
              />
            </a>
          </div>
        </div>
      </div>
      {showResetPassword ? (
        <div className="reset-password-outer-container">
          <div className="reset-password-container">
            <div className="reset-password-inner-container">
              <div className="reset-password-content-container">
                <div className="reset-password-content-header-container">
                  <div className="reset-password-content-header">
                    <h1 className="reset-password-header-text">
                      Reset your password
                    </h1>
                  </div>
                  <div className="reset-password-xbutton-container">
                    <img
                      className="reset-password-xbutton"
                      src={close}
                      alt="x-icon"
                      onClick={toggleResetPassword}
                    />
                  </div>
                </div>
                <div className="reset-password-content-description-container">
                  <h1 className="reset-password-content-description">
                    Please enter your email with which you created an account on
                    web3onwards. We will send you a link to reset the account
                    password
                  </h1>
                </div>
                <div className="reset-password-content-input-container">
                  <input
                    type="email"
                    className={`input-field ${resetError ? "input-error" : ""}`}
                    placeholder="Email address"
                    value={registeredEmail}
                    onChange={(e) => setRegisteredEmail(e.target.value)}
                  />
                </div>
                {resetError && (
                  <div className="reset-password-error-container">
                    <div className="error-container">
                      <div className="eror-header-container">
                        <img className="info-icon" src={info} alt="info" />
                        <h3 className="error-header">Email is not valid</h3>
                      </div>
                      <div className="error-content-container">
                        <h3 className="error-content">
                          Please Check your email address again
                        </h3>
                      </div>
                    </div>
                    <div className="x-icon-container">
                      <img
                        className="x-icon"
                        src={close}
                        alt="x-icon"
                        onClick={clearError}
                      />
                    </div>
                  </div>
                )}
                {networkError && (
                  <div className="reset-password-error-container">
                    <div className="error-container">
                      <div className="eror-header-container">
                        <img className="info-icon" src={info} alt="info" />
                        <h3 className="error-header">Something went wrong</h3>
                      </div>
                      <div className="error-content-container">
                        <h3 className="error-content">{networkError}</h3>
                      </div>
                    </div>
                    <div className="x-icon-container">
                      <img
                        className="x-icon"
                        src={close}
                        alt="x-icon"
                        onClick={clearError}
                      />
                    </div>
                  </div>
                )}
                <div className="reset-password-button-container">
                  <img
                    src={resetpwdbtn1}
                    alt="info"
                    onClick={handleResetPassword}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="login-page-container">
          <div className="login-container">
            <div className="left-box-container">
              <div className="left-box">
                UPSKILL <br></br>BUILD GROW
              </div>
              <div className="login-header-container">
                <div className="login-header">
                  Seamlessly access the world of<br></br> decentralized
                  applications
                </div>
              </div>
            </div>
            <div className="login-right-box-container">
              <div className="login-right-box">
                <div
                  className="google-auth-outer-container"
                  onClick={handleGoogleLogin}
                >
                  <div className="google-auth-button">
                    <img src={googleAuth} />
                    Continue with Google
                  </div>
                </div>
                <div className="google-auth-bottom-outer-container">
                  <div className="auth-vector-container"></div>
                  <div className="google-auth-bottom-container">
                    <h3 className="google-auth-bottom">or</h3>
                  </div>
                  <div className="auth-vector-container"></div>
                </div>
                {/* <div className="login-header-container">
        <div className="login-header">Seamlessly access the world of decentralized applications</div> 
        </div> */}
                <div className="input-field-container">
                  <input
                    type="text"
                    className={`input-field ${
                      errorType === "USER NOT FOUND IN DB" ? "error1" : ""
                    }`}
                    placeholder="Personal Email ID"
                    value={userName}
                    onChange={handleUsernameChange}
                  />
                  <input
                    type="password"
                    className={`input-field ${
                      errorType === "USERNAME OR PASSWORD MISMATCHED"
                        ? "error1"
                        : ""
                    }`}
                    placeholder="Password"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                </div>
                <div className="forgot-password-container">
                  <h3
                    className="forgot-password-text"
                    onClick={toggleResetPassword}
                  >
                    Forgot password ?
                  </h3>
                </div>

                <div className="login-box">
                  {errorType && (
                    <div
                      className={`error-message-${errorType
                        .replace(/\s+/g, "-")
                        .toLowerCase()}`}
                    >
                      {errorType === "USERNAME OR PASSWORD MISMATCHED" ? (
                        <div className="error-outer-counter">
                          <div className="error-container">
                            <div className="eror-header-container">
                              <img
                                className="info-icon"
                                src={info}
                                alt="info"
                              />
                              <h3 className="error-header">
                                Incorrect password !!!
                              </h3>
                            </div>
                            <div className="error-content-container">
                              <h3 className="error-content">
                                Please recheck your password and try again.
                              </h3>
                            </div>
                          </div>
                          <div className="x-icon-container">
                            <img
                              className="x-icon"
                              src={close}
                              alt="x-icon"
                              onClick={clearError}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="error-outer-counter">
                          <div className="error-container">
                            <div className="eror-header-container">
                              <img
                                className="info-icon"
                                src={info}
                                alt="info"
                              />
                              <h3 className="error-header">
                                This email is not Registered
                              </h3>
                            </div>
                            <div className="error-content-container">
                              <h3 className="error-content">
                                Create an account.{" "}
                                <a href="/coursesignup">Register Here</a>
                              </h3>
                            </div>
                          </div>
                          <div className="x-icon-container">
                            <img
                              className="x-icon"
                              src={close}
                              alt="x-icon"
                              onClick={clearError}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  <img
                    className="login-button"
                    src={loginButton}
                    alt="Login"
                    onClick={handleLoginClick}
                  />
                </div>
                <div className="signup-link-container">
                  <div className="signup-link">
                    Dont have an account?{" "}
                    <a href="/coursesignup">Create account</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
// export const UserProvider = ({ children }) => {
//   const [userId, setUserId] = useState(null); // Store the user ID

//   return (
//     <UserContext.Provider value={{ userId, setUserId }}>
//       {children}
//     </UserContext.Provider>
//   );
// };

export default LoginPage;
