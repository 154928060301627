import "./ContactPage.css";
import React from "react";
// import RegistrationForm from "../registrationForm
import Header from "../header";
import ContactForm from "../ContactForm/ContactForm";
// import Header from "../header";
import { BsTwitter, BsInstagram } from "react-icons/bs";
import { useEffect } from "react";

const ContactPage = () => {
  const platform = window.navigator.platform;
  useEffect(() => {
    if (platform == "Win32") {
      console.log(platform);

      document.getElementsByClassName("hack")[0].style.marginTop = "-25px";
    }
    localStorage.setItem("user","college")
  }, []);

  return (
    <div className="main">
      <div className="header">
        <Header />
      </div>

      <div className="frame">
      <div className="left">
          <div className="top">
            <div className="top-f">
              <t className="date">Your gateway to the new internet</t>
              {/* <t className="bng">WORKSHOP</t> */}
              <t className="bng">web3 community</t>
            </div>
            {/* <div className="hack">HACKATHON</div> */}
            <div className="hack">web3onwards</div>
            <div className="desc">
              {/* Register using this form to officially be a part of the hackathon
              and receive a 1 of 1 NFT through our NFT minter. */}
             Web3onwards can add another dimension to your college’s learning experience. Get in touch with us today by filling out the form.
            </div>
          </div>
          <div className="socials desktop">
            <div class="gentext">
              {" "}
              //GENERATING <br></br> SOCIAL LINKS FROM INDEX
            </div>
            <h2>FOLLOW US ON</h2>
            <div className="links">
              <a href="https://twitter.com/NFThing_" target="_blank" rel="noreferrer">
                <BsTwitter className="icon" /> <p>TWITTER</p>
              </a>
              <a
                href="https://instagram.com/nfthing_?igshid=YmMyMTA2M2Y="
                target="_blank"
                rel="noreferrer"
              >
                <BsInstagram className="icon" /> <p>INSTAGRAM</p>
              </a>
            </div>
          </div>
        </div>
        <div className="right">
          <ContactForm/>
        </div>
      </div>

      <div class="dots_lines desktop">
        <div
          class="sq"
          style={{ position: "absolute", left: "25.40vw", top: "80vh" }}
        ></div>
        <div
          class="sq"
          style={{
            position: "absolute",
            left: "25.40vw",
            top: "80vh",
            marginLeft: "15px",
          }}
        ></div>
        <div
          class="sq"
          style={{ position: "absolute", left: "48.4vw", top: "74.35vh" }}
        ></div>
        <div
          class="sq"
          style={{ position: "absolute", left: "24.8vw", top: "55vh" }}
        ></div>
        <div
          class="sq"
          style={{ position: "absolute", left: "42.5vw", top: "24.8vh" }}
        ></div>

        <div
          class="regform"
          style={{
            display: "flex",
            position: "absolute",
            left: "49.5vw",
            top: "60.2vh",
            transform: "rotate(-90deg)",
            alignItems: "center",
          }}
        >
          <div class="sq"></div>
          <div class="regtext" style={{ marginLeft: "15px" }}>
            REGISTRATION FORM
          </div>
        </div>

        <div
          class="linedot"
          style={{
            position: "absolute",
            left: "47px",
            top: "58.7vh",
            transform: "rotate(90deg)",
          }}
        >
          <div class="linethin"></div>
          <div class="sq3" style={{ top: "-59px" }}></div>
          <div class="sq3" style={{ top: "-71px", marginBottom: "12px" }}></div>
        </div>

        <div
          class="4square"
          style={{
            display: "block",
            position: "absolute",
            left: "45.3vw",
            top: "57.6vh",
          }}
        >
          <div style={{ display: "flex" }}>
            <div class="sq3" style={{ position: "unset" }}></div>
            <div
              class="sq3"
              style={{ marginLeft: "3px", position: "unset" }}
            ></div>
          </div>

          <div style={{ display: "flex", marginTop: "3px" }}>
            <div class="sq3" style={{ position: "unset" }}></div>
            <div
              class="sq3"
              style={{ marginLeft: "3px", position: "unset" }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
