import React from 'react'
import "./Animatedlist.css"
import hat from "../../../assets/Images/magic_hat.png"
import bubble from "../../../assets/Images/bubble.png"
import calendar from "../../../assets/Images/calendar.png"
import computer from "../../../assets/Images/computer.png"

const Animatedlist = () => {
  return (
    <div className="content">
  <div className="content__container">
    
    <ul className="content__container__list">
      <li className="content__container__list__item"><img src={bubble} alt="bubble" /> <span>Marketers</span></li>
      <li className="content__container__list__item"><img src={calendar} alt="calendar" />Managers</li>
      <li className="content__container__list__item"><img src={hat} alt="hat" />Designers</li>
      <li className="content__container__list__item"><img src={computer} alt="computer" />Developers</li>
    </ul>
  </div>
</div>
  )
}

export default Animatedlist