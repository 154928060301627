  import React, {
      useRef,
      useState,
      useLayoutEffect,
      useCallback,
      useEffect
    } from "react";
    import ResizeObserver from "resize-observer-polyfill";
    import {
      motion,
      useScroll,
      useTransform,
      useSpring,
      useMotionValue
    } from "framer-motion";
    
    import { useScrollPercentage } from "react-scroll-percentage";
    import "./Frame3.css"
    
    const Frame3 = () => {
      const scrollRef = useRef(null);
      const ghostRef = useRef(null);
      const [scrollRange, setScrollRange] = useState(0);
      const [viewportW, setViewportW] = useState(0);
    
      const scrollPerc = useMotionValue(0);
    
      useLayoutEffect(() => {
        scrollRef && setScrollRange(scrollRef.current.scrollWidth);
        console.log(scrollRef.current.scrollWidth)
      }, [scrollRef]);
    
      const onResize = useCallback((entries) => {
        for (let entry of entries) {
          setViewportW(entry.contentRect.width);
        } 
      }, []);
    
      useLayoutEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => onResize(entries));
        resizeObserver.observe(ghostRef.current);
        return () => resizeObserver.disconnect();
      }, [onResize]);   
    
  
    
      const [containerRef, percentage] = useScrollPercentage({
        /* Optional options */
        threshold: 0.21
      });
    
      useEffect(() => {
        scrollPerc.set(percentage);
        console.log(scrollRef.current.scrollWidth)
      }, [percentage]);
    
      const transform = useTransform(
        scrollPerc,
        [0.1, 0.8],
        [0, -scrollRange + viewportW]  
      );

      
      const physics = { damping: 12, mass: 0.25, stiffness: 200 };
      const spring = useSpring(transform, physics);
    
      return (
        <div ref={containerRef}>
          <div className="scroll-container">
            
            <motion.section
              ref={scrollRef}
              style={{ x: spring }}
              className="question"
            >
              <h1 className="question">WHY WEB3ONWARDS? WE’RE GLAD YOU ASKED.</h1>
            </motion.section>
          
            <motion.section
              ref={scrollRef}
              style={{ x: spring }}
              className="thumbnails-container"
            >
              <div className="thumbnails">
                <div className="thumbnail"><p><p className="inline-pp">BUILD</p> the new internet together</p></div>
                <div className="thumbnail"><p> <p className="inline-pp">INCUBATE</p> your ideas from POC to startups</p></div>
                <div className="thumbnail"><p><p className="inline-pp">UPSKILL</p> your web3 and blockchain knowledge/skills</p></div>
                <div className="thumbnail"><p>Be part of a community of<p className="inline-pp"> LIKE MINDED INDIVIDUALS</p></p></div>
                <div className="thumbnail"><p><p className="inline-pp"> CONTRIBUTE</p> to opensource web3 projects</p></div>
                 
                
              </div>
            </motion.section>
          </div>
          <div ref={ghostRef} style={{ height: scrollRange }} className="ghost" />
        </div>
      );
    };
    
    export default Frame3;
    