import React, { useEffect, useState } from "react";
import ExpandMore from "../../../../assets/Images/expand_more .svg";
import ExpandLess from "../../../../assets/Images/expand_less.svg";
import playCircle from "../../../../assets/Images/course_desc_play_circle.svg";
import Alarm from "../../../../assets/Images/alarm.png";
import CompleteLogo from "../../../../assets/Images/course_desc_completed.png";
import PendingLogo from "../../../../assets/Images/course_desc_pending.png";
import StartLogo from "../../../../assets/Images/course_desc_start.png";
import TaskScreenOverlay from "./TaskScreenOverlay";
import axios from "axios";

const CourseContent = ({ level, time, circleColor, initialTasks = [],open, onToggle, courseIdparam, fromGotoTask, linkOfTask, discordLink, submissionLink}) => {
  const [show, setShow] = useState(open);
  const [courseId,setCourseId] = useState(courseIdparam)
  const [selectedTaskLink, setSelectedTaskLink] = useState(null);
  const [selectedTaskSubmissionLink, setSelectedTaskSubmissionLink] = useState(null);
  const [selectedTaskDiscordLink, setSelectedTaskDiscordLink] = useState(null);
  const [tasks, setTasks] = useState(initialTasks);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const authToken = window.localStorage.getItem("jwtToken")

  useEffect(()=>{
    setCourseId(courseIdparam)
    console.log(courseIdparam)
  },[courseIdparam])
 // console.log(authToken)

  useEffect(()=>{
    setShow(open) 
  },[open])

  useEffect(()=>{
    setTasks(initialTasks)
  },[initialTasks])

  useEffect(() => {
    if (fromGotoTask) {
      // Use the passed props here
      setSelectedTaskLink(linkOfTask);
      setSelectedTaskSubmissionLink(submissionLink);
      setSelectedTaskDiscordLink(discordLink);
      setOverlayVisible(true);
        }
  }, [fromGotoTask, linkOfTask, discordLink, submissionLink]);

  const updateTaskStatus = (taskId, status) => {
    setTasks(prevTasks => 
      prevTasks.map(task => 
        task.taskId === taskId ? { ...task, completionStatus: status } : task
      )
    );
  };


  const handleCloseOverlay = () => {
    console.log("Clicked close")
    setSelectedTaskLink(null);
    setSelectedTaskSubmissionLink(null);
    setSelectedTaskDiscordLink(null);
    setOverlayVisible(false);
  };

  const handleStatusClick = async (task) => {
    try {
      const startTaskResponse = await axios.post(
        "https://auth.web3onwards.com/starttask",
        { courseId, taskId: task.taskId },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      //console.log('Start Task Response:', startTaskResponse);
      if (startTaskResponse.status === 200) {
        const { linkOfTask } = startTaskResponse.data;
        const { submissionLink } = startTaskResponse.data;
        const { discordLink } = startTaskResponse.data;
        setSelectedTaskLink(linkOfTask);
        setSelectedTaskSubmissionLink(submissionLink);
        setSelectedTaskDiscordLink(discordLink);
       updateTaskStatus(task.taskId, 'INPROGRESS');
      }
    } catch (error) {
      console.error('Error Response:', error.response);
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.error === "USER DID NOT ENROLLED IN THIS COURSE"
      ) {
        console.log("User not enrolled in course. Enrolling now...");
  
        try {
          const enrollResponse = await axios.post(
            "https://auth.web3onwards.com/courseuser",
            { courseId },
            { headers: { Authorization: `Bearer ${authToken}` } }
          );
          //console.log('Enroll Response:', enrollResponse);

          if (enrollResponse.status === 200) {
            const retryStartTaskResponse = await axios.post(
              "https://auth.web3onwards.com/starttask",
              { courseId, taskId: task.taskId },
              { headers: { Authorization: `Bearer ${authToken}` } }
            );
            //console.log('Retry Start Task Response:', retryStartTaskResponse);

            if (retryStartTaskResponse.status === 200) {
              const { linkOfTask } = retryStartTaskResponse.data;
              const { submissionLink } = retryStartTaskResponse.data;
              const { discordLink } = retryStartTaskResponse.data;
              //console.log('Link of Task after retry:', linkOfTask);
              setSelectedTaskLink(linkOfTask);
              setSelectedTaskSubmissionLink(submissionLink);
              setSelectedTaskDiscordLink(discordLink);
              updateTaskStatus(task.taskId, 'INPROGRESS');
            }
          }
        } catch (enrollError) {
          //console.error("Enroll Error:", enrollError);
          alert("Error enrolling in the course.");
        }
      } else if (error.response && error.response.data.error === "TASK HAS BEEN ALREADY STARTED") {
        const { linkOfTask } = error.response.data;
        const { submissionLink } = error.response.data;
        const { discordLink } = error.response.data;
     // console.log('Link of Task for already started:', linkOfTask);
        setSelectedTaskLink(linkOfTask);
        setSelectedTaskSubmissionLink(submissionLink);
        setSelectedTaskDiscordLink(discordLink);
        updateTaskStatus(task.taskId, 'INPROGRESS');
      }else {
        //console.error("Error starting task:", error);
        alert("An error occurred while starting the task.");
      }
    }
  };

  if (!tasks.length) {
    return <div>Loading tasks...</div>; 
  }


  return (
    <>
      <div className="content-header">
        <div className="content-level">
          <div className={`${circleColor}`}></div>
          <div className="content-header-heading">{tasks[0].level}</div>
        </div>
        <div className="content-time">
          <div className="content-text">{time}</div>
          {show ? (
            <img
              onClick={onToggle}
              src={ExpandLess}
              className="expand-more"
              alt=""
            />
          ) : (
            <img
              onClick={onToggle}
              src={ExpandMore}
              className="expand-more"
              alt=""
            />
          )}
        </div>
      </div>
      {show && (
        <div className="content-header-content">
          <div className="content-header-box">
            {tasks.map((task) => (
              <div className="content-course-detail" key={task.taskName}>
                <div className="content-course-detail-text">
                  <div className="content-course-detail-text-header">
                    <img src={playCircle} alt="" />
                    <div className="text">{task.taskName}</div>
                    <img className="image" src={Alarm} alt="" />
                    <div className="text">{task.taskMinutes} minutes</div>
                  </div>
                  <div className="text-para">{task.taskDescription}</div>
                </div>
                <div className="status" onClick={() => handleStatusClick(task)}>
                  {task.completionStatus === 'Not Started' && (
                    <img
                      src={StartLogo}
                      alt=""
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                  {task.completionStatus === 'Completed' && (
                    <img
                      src={CompleteLogo}
                      alt=""
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                  {task.completionStatus === 'INPROGRESS' && (
                    <img
                      src={PendingLogo}
                      alt=""
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {selectedTaskLink && (
        <TaskScreenOverlay link={selectedTaskLink} TaskSubmissionLink={selectedTaskSubmissionLink} TaskDiscordLink={selectedTaskDiscordLink} onClose={handleCloseOverlay} />
      )}
      {overlayVisible && (
        <TaskScreenOverlay link={selectedTaskLink} TaskSubmissionLink={selectedTaskSubmissionLink} TaskDiscordLink={selectedTaskDiscordLink} onClose={handleCloseOverlay} />
      )}
    </>
  );
};


export default CourseContent;
