import "./Frrame5b.css"
import React from 'react'

const Frame5b = () => {
  return (
    <div className="frame-5b" id="frame-5b">
        <div className="f5b-ques">
            <h1 className="how">HOW IT WORKS</h1>
        </div>
        <div className="f5b-access">
            <h2 className="f5b-subtitle">Get access via NFT ID</h2>
            <h3 className="f5b-desc">JOIN OUR COMMUNITY</h3>
            <p className="f5b-content">In order to join our community, you will have to have a web3onwards NFT. This is available for free only to approved universities. Once your university is approved you will be able to mint an NFT. To get your university approved, please click the contact us button in the join us section.</p>
        </div>
        <div className="long-border"></div>
        <div className="f5b-access">
            <h2 className="f5b-subtitle">EDUCATION on web3</h2>
            <h3 className="f5b-desc">LEARN ABOUT THE NEW INTERNET</h3>
            <p className="f5b-content">Once you are in the community, we will upgrade your skills for the new internet. In this exciting phase, you will get to collaborate with like-minded peers, network and learn. You're going to create value within the Web3 ecosystem. At the end of this journey, you will not only have a good understanding of blockchain and web3 but also gain hands-on experience and perhaps even start your own blockchain initiative.</p>
        </div>
        <div className="long-border"></div>
        <div className="f5b-access">
            <h2 className="f5b-subtitle">BUILD the new internet</h2>
            <h3 className="f5b-desc">BECOME A PART OF THE COMMUNITY THAT BUILDS</h3>
            <p className="f5b-content">Together we will build the new internet for the betterment of the common people from the comfort of your home. We will build apps and protocols to disrupt the AirBnb’s, Meta’s and other aggregators that are taking money out of local systems. We will enable this money to flow back to the local communities.. in this way changing commerce for the good forever.</p>
        </div>
    </div>
  )
}

export default Frame5b