import React, { useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
//import { useUserContext } from './UserContext';
import "./CrsResetPwd.css";
import web3button from "../../../assets/Images/web3button.svg";
import coursesButton from "../../../assets/Images/coursesButton.svg";
import close from "../../../assets/Images/close.svg";
import navContributors from "../../../assets/Images/nav_contri.svg";
import info from "../../../assets/Images/info.svg";
import resetpwdbtn2 from "../../../assets/Images/ResetPwdBtn2.svg";
import resetpwdbtn3 from "../../../assets/Images/ResetPwdBtn3.svg";
import visibilityIcon from "../../../assets/Images/visibility.svg";
import visibilityOffIcon from "../../../assets/Images/visibility_off.svg";
import loginSignup from "../../../assets/Images/loginSignup.svg";

const token = "a7869067837b95b2a2a76f4af7cb7b86dc86f5f6";

const CrsResetPassword = () => {
  const [passwordReset, setPasswordReset] = useState('');
  const [passwordStrength, setPasswordStrength] = useState({ label: '', color: 'red' });
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordTyped, setPasswordTyped] = useState(false);
  const [error, setError] = useState('');
  const [confirmationError, setconfirmationError] = useState('');
  const [submissionError, setSubmissionError] = useState('');
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);
  //const { token } = useParams();
  const navigate = useNavigate(); 
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [confirmPasswordVisibility, setConfirmPasswordVisibility] = useState(false);

  const evaluatePasswordStrength = (password) => {
    if (/[\s<,.#]/.test(password)) {
      setError('<,.# and spaces.');
      return { label: '', color: 'black' };
    } else {
      setError('');
    }

    let strength = 0;
    if (password.length > 5) strength += 1;
    if (password.length > 8) strength += 1;
    if (/[A-Z]/.test(password)) strength += 1;
    if (/[0-9]/.test(password)) strength += 1;
    if (/[^A-Za-z0-9]/.test(password)) strength += 1;

    switch (strength) {
      case 0:
      case 1:
      case 2:
        return { label: "LOW", color: "red" };
      case 3:
      case 4:
        return { label: "MEDIUM", color: "orange" };
      default:
        return { label: "HIGH", color: "green" };
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPasswordReset(newPassword);
    setPasswordTyped(true);
    const strength = evaluatePasswordStrength(newPassword);
    setPasswordStrength(strength);
  };
  const handleConfirmPasswordChange = (e) => {
    const newPassword = e.target.value;
    setConfirmPassword(newPassword);
    if (newPassword === passwordReset) {
      setconfirmationError('');
    } else {
      setconfirmationError('Passwords do not match.');
    }
  };
  const handleSubmit = async () => {
    if (!confirmationError && passwordReset === confirmPassword) {
      const url = `https://auth.web3onwards.com/reset-password/${token}`;  // Use token in the URL path
      try {
        console.log('Sending request to:', url);
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            password: passwordReset  // Send only password if token is in the URL
          }),
        });
        const data = await response.json();
        console.log('Server response:', data);
        if (response.ok) {
          setIsSubmittedSuccessfully(true); 
        } else {
          setSubmissionError(data.message || 'Failed to reset password');
        }
      } catch (err) {
        console.error('Error connecting to server:', err);
        setSubmissionError('Failed to connect to the server');
      }
    } else {
      setconfirmationError('Passwords do not match.');
    }
  };

  const clearError = () => {
    setError('');
    setconfirmationError('');
    setSubmissionError('');
  };
  const toggleResetPassword = () => {
    setIsSubmittedSuccessfully(prev => !prev); 
  };

  const togglePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisibility(!confirmPasswordVisibility);
  };

    return (
      <>
<div className="nav-bar-container">
          <div className="page-navigation-container1">
          <div className="nav-button-container">
          <a href='/'>
          <img src={web3button} alt="web3button"/>
          </a>
          </div>
          <div className="nav-button-container">
            <a href='/course'>
          <img src={coursesButton} alt="coursesButton" />
          </a>
          </div>
          </div>
          <div className="page-navigation-container2">
          <div className="nav-button-container">
          <a href='/'>
          <img className='nav-contributors-button' src={navContributors} alt="web3button"/>
          </a>
          </div>
          <div className="nav-button-container">
            <a href='/courselogin'>
          <img className='nav-loginSignup-button'src={loginSignup} alt="coursesButton" />
          </a>
          </div>
          </div>
        </div> 
        {!isSubmittedSuccessfully ? (
        <div className="reset-password-outer-container">
           <div className="reset-password-container">
           <div className="reset-password-inner-container">
           <div className="reset-password-content-container">
           <div className="reset-password-content-header-container">
           <div className="reset-password-content-header">
            <h1 className="reset-password-header-text">Reset your password</h1>
           </div>
           <div className='reset-password-xbutton-container'>
            <a href='/courselogin'>
           <img className='reset-password-xbutton' src={close} alt='x-icon'/>
           </a>
           </div>
           </div>
           <div className='input-field-header-container1'>
            <h3 className='input-field-header'>Enter new password</h3>
           </div>
           <div className='reset-password-input-container1'>
           <input
            type={passwordVisibility ? "text" : "password"}
            className={`crsreset-input-field ${error ? 'error' : ''}`}
            value={passwordReset}
            onChange={handlePasswordChange}
          />
          <div className={`visibility-toggle-button-container ${error ? 'visibility-toggle-button-container-error' : ''}`}>
           <img src={passwordVisibility ? visibilityOffIcon : visibilityIcon} onClick={togglePasswordVisibility} alt="toggle visibility" />
           </div>
           </div>
           {passwordTyped && (
           <div className='password-strength-outer-container'>
           <div className='password-strength-container'>
           <div className='password-strength-text-container'>
            <h4 className='password-strength'>Password strength:</h4>
           </div>
           <div className='password-strength-indicator-container'>
            <h4 className='password-strength-indicator' style={{ color: passwordStrength.color }}>{passwordStrength.label}</h4>
           </div>
           </div>
           </div>
           )}
           <div className='input-field-header-container2'>
            <h3 className='input-field-header'>Re-enter your new password</h3>
           </div>
           <div className='reset-password-input-container2'>
           <input
            type={confirmPasswordVisibility ? "text" : "password"}
            className={`crsreset-input-field ${confirmationError ? 'error' : ''}`}
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
          />
          <div className={`visibility-toggle-button-container ${confirmationError ? 'visibility-toggle-button-container-error' : ''}`}>
           <img src={confirmPasswordVisibility ? visibilityOffIcon : visibilityIcon} onClick={toggleConfirmPasswordVisibility} alt="toggle visibility" />
           </div>
           </div>
            {error && <div className='pwd-error-message-container'>
            <div className='pwd-error-outer-counter'>
            <div className="pwd-error-container">
              <div className='pwd-eror-header-container'>
                <img className='pwd-info-icon' src={info} alt='info'/>
                <h3 className='pwd-error-header'>Create a different Password</h3>
              </div>
              <div className='pwd-error-content-container'>
                <h3 className='pwd-error-content'>{error} please exclude these characters and create a strong password</h3>
              </div>
            </div>
            <div className='pwd-x-icon-container'>
            <img className='pwd-x-icon' src={close} alt='x-icon' onClick={clearError}/>
            </div>
            </div>
            </div>
            }
            {confirmationError && <div className='pwd-error-message-container'>
            <div className='pwd-error-outer-counter'>
            <div className="pwd-error-container">
              <div className='pwd-eror-header-container'>
                <img className='pwd-info-icon' src={info} alt='info'/>
                <h3 className='pwd-error-header'>Passwords do NOT match.</h3>
              </div>
            </div>
            <div className='pwd-x-icon-container'>
            <img className='pwd-x-icon' src={close} alt='x-icon' onClick={clearError}/>
            </div>
            </div>
            </div>
            }
            {submissionError && <div className='pwd-error-message-container'>
            <div className='pwd-error-outer-counter'>
            <div className="pwd-error-container">
              <div className='pwd-eror-header-container'>
                <img className='pwd-info-icon' src={info} alt='info'/>
                <h3 className='pwd-error-header'>Reset failed</h3>
              </div>
              <div className='pwd-error-content-container'>
                <h3 className='pwd-error-content'>{submissionError}</h3>
              </div>
            </div>
            <div className='pwd-x-icon-container'>
            <img className='pwd-x-icon' src={close} alt='x-icon' onClick={clearError}/>
            </div>
            </div>
            </div>
            }
           <div className='reset-password-button-container'>
           <img src={resetpwdbtn2} alt='Reset-pwd-btn' onClick={handleSubmit}/> 
           </div>
           </div>
           </div>
            </div> 
        </div>
        ): (
          <div className="reset-password-outer-container">
          <div className="reset-password-container">
          <div className="reset-password-inner-container">
          <div className="reset-password-content-container">
          <div className="reset-password-content-header-container">
          <div className="reset-password-content-header">
           <h1 className="reset-password-header-text">You have successfully changed your password</h1>
          </div>
          <div className='reset-password-xbutton-container'>
          <img className='reset-password-xbutton' src={close} alt='x-icon' onClick={toggleResetPassword}/>
          </div>
          </div>
          <div className="reset-password-content-description-container">
          <h1 className="reset-password-content-description">Please enter your email with which you created an account on webb3onwards. We will send you a link to reset the account password</h1>
          </div>
          <div className='reset-password-button-container'>
          <img src={resetpwdbtn3} alt='login-page' onClick={() => navigate('/courselogin')}/> 
          </div>
          </div>
          </div>
           </div> 
       </div>
        )}
      </>
    );
  };
  
  
  export default CrsResetPassword;