import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { BsTwitter, BsInstagram,} from "react-icons/bs";
import DiscordButton from "../components/DiscordButton/discordButton.js";
import "./RegistrationForm.css";

function RegistrationForm(props) {
  const location= useLocation()
  const queryParams = new URLSearchParams(location.search);
  const queryId = queryParams.get('discordId');
  const url = "https://api.nfthing.com/form";
  // const url = "http://127.0.0.1:3001/form";
  const [submitClicked, setSubmitClicked] = useState(false);
  const [ritEmail, setritEmail] = useState("");
  const [Name, setName] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [discordId, setDiscord] = useState(queryId||"")
  const login=props.login
  const {outCollege, setOutCollege} = useState("")
  const [role, setRole] = useState("")
  const [college, setCollege]= useState("")
  const [walletValid, setWalletValid] = useState(false); 
  const [OutcollegeValid, setOutcollegeValid] = useState(false)
  const [walletAddress, setwalletAddress] = useState(null);
  const [newsletter, setNewsletter] = useState(false);
  const [nameValid, setnameValid] = useState(false);
  const [phoneValid, setphonevalid] = useState(false);
  const [emailValid, setemailValid] = useState(false);
  // const [discordValid, setDiscordValid]= useState(false)
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (id === "Name") {
      setName(value);
      if (value.length > 1) {
        setnameValid(true);
      } else {
        setnameValid(false);
      }
    }
    // if (id === "ritEmail") {
    //   setritEmail(value);
    //   if (/^\w+([\.-]?\w+)*@msrit.edu$/.test(value)) {
    //     setphonevalid(true);
    //   } else {
    //     setphonevalid(false);
    //   }
    // }

    if (id === "phoneNumber") {
      setphoneNumber(value);
      if (value.length >= 10) {
        setphonevalid(true);
      } else {
        setphonevalid(false);
      }
    }
    if (id === "email") {
      setEmail(value);
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        setemailValid(true);
      } else {
        setemailValid(false);
      }
    }
    if (id === "walletAddress") {
      setwalletAddress(value);
      if (/^0x[a-fA-F0-9]{40}$/g.test(value)) {
        setWalletValid(true);
      } else {
        setWalletValid(false);
      }
    }
      // if (id === "confirmwalletAddress") {
      //   setConfirmwalletAddress(value);
      // }
    if (id === "newsletter") {
      setNewsletter(e.target.checked);
    }
    if (id==="discord"){
      setDiscord(value);
      // if(/^.{3,32}#[0-9]{4}$/.test(value)){
      //   setDiscordValid(true)
      // }
      // else{
      //   setDiscordValid(false)
      // }
    }
    if(id === "college_drop"){
      setCollege(value)
      value==="Enthusiast"?setRole("Enthusiast"):setRole("Probable")
    }
    if(id==="Outcollege"){
      setCollege(value)
    }
    
  };

  const validateForm = () => {
    setSubmitClicked(true);

    if (email === "") {
      setemailValid(false);
    }
    if (Name === "") {
      setnameValid(false);
    }
    if (phoneNumber === "") {
      setphonevalid(false);
    }
    if (email === "" && Name === "" && phoneNumber === "") {
      console.log("enter fields");
    } else {
      handleSubmit();
    }
  };



  const handleSubmit = () => {
    console.log(nameValid, phoneValid, emailValid);
    // if(discordId==null){
    //   setDiscord("")
    // }
    if (nameValid && phoneValid && emailValid && college!=="") {
      sendData();
    } else {
      alert("Please enter all input fields" )
    }
  };


const sendData = () => {
    // Simple POST request with a JSON body using fetch
    const requestOptions = {
      // mode: 'no-cors',
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        firstName: Name,
        phone: phoneNumber,
        email: email,
        discordId: discordId,
        role:role,
        newsletter: newsletter,
        college: college,
      }),
    };
    console.log(requestOptions)
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => responses(data));
  };

  const responses = (data) => {
    console.log(data)
    if (data.message==="user updated succesfully") {
      localStorage.setItem("isAllowed",true)
      navigate("/form/success");
    }else if(data.message==='User not found'){
      alert("Please login to your discord")
      navigate("/login")
    }
     else {
      navigate("/error");
    }
  };    

  if(!login){
    
    return <div className="form">
        <DiscordButton link="https://discord.com/api/oauth2/authorize?client_id=1134005104111013960&redirect_uri=https%3A%2F%2Fapi.nfthing.com%2Fapi%2Fauth%2Fdiscord%2Fredirect&response_type=code&scope=identify"/>
      </div>
  }

  return (
    <>
      <div className="form">
        <div className="form-body">
          <div className="username">
            <input
              className="form__input"
              type="text"
              value={Name}
              onChange={(e) => handleInputChange(e)}
              style={
                submitClicked && !nameValid
                  ? { borderBottom: "0.5px solid red" }
                  : {}
              }
              id="Name"
              placeholder="Enter your name*"
              required
            />
          </div>
          
          <p
            className="error"
            style={submitClicked && !nameValid ? { color: "red" } : {}}
          >
            Enter your name
          </p>
           <div className="phoneNumber">
            <input
              type="tel"
              name=""
              id="phoneNumber"
              value={phoneNumber}
              className="form__input"
              style={
                submitClicked && !phoneValid
                  ? { borderBottom: "0.5px solid red" }
                  : {}
              }
              onChange={(e) => handleInputChange(e)}
              placeholder="Phone Number*"
              required
            />
          </div>
          {/*RIT Email*/}
          {/* <div className="ritEmail">
            <input
              type="email"
              name=""
              id="ritEmail"
              value={ritEmail}
              className="form__input"
              style={
                submitClicked && !phoneValid
                  ? { borderBottom: "0.5px solid red" }
                  : {}
              }
              onChange={(e) => handleInputChange(e)}
              placeholder="University Email id*"
              required
            />
          </div> */}
        <p
          className="error"
          style={submitClicked && !phoneValid ? { color: "red" } : {}}
        >
          Enter a valid phone number
          {/* Enter a valid RIT Address */}
        </p>
          <div className="email">
            <input
              type="email"
              id="email"
              className="form__input"
              value={email}
              onChange={(e) => handleInputChange(e)}
              style={
                submitClicked && !emailValid
                  ? { borderBottom: "0.5px solid red" }
                  : {}
              }
              placeholder="Email Address*"
              required
            />
          </div>
          <p
            className="error"
            style={submitClicked && !emailValid ? { color: "red" } : {}}
          >
            Enter a valid email id
          </p>
          
            
            <div>
            <select name="select_opt" id="college_drop" className="form__input" onChange={(e) => handleInputChange(e)} value={college} required> 
	              <option value="">Please Select College</option>  
	              <option value="BMSIT">BMS Institute of Technology</option>
	              <option value="RIT">Ramiah Institute of Technology</option>
	              <option value="JGI">Jain Group of Institutions (JGI)</option>
	              <option value="NHCE">New Horizon College of Engineering</option>
	              <option value="RNSIT">RNS Institute of technology</option>
	              <option value="Enthusiast">Other, Please Specify</option>
            </select> 
            <input
              type="text"
              id="Outcollege"
              className="form__input"
              value={outCollege}
              onChange={(e) => handleInputChange(e)}
              style={
                {display : role==="Enthusiast"?"block":"none", borderBottom: submitClicked&& !OutcollegeValid?"0.5px solid red":"" }
              }
              
              placeholder="College name"
              required
            />
            </div>
          
          <div className="newsletters">
            {/* <label className="form__label" for="newsletter">
              Newsletter{" "}
            </label> */}
            <input
              className="checkbox1"
              type="checkbox"
              value={true}
              id="newsletter"
              onChange={(e) => handleInputChange(e)}
              placeholder="newsletter"
            />
            <label for="checkbox" className="form__label">
              {/* Receive updates about NFThing on your email through our newsletter */}
              Get the latest news in web3 and our job postings in your inbox
              every month
            </label>
          </div>
        </div>
        <div className="form-footer">
          <div class="linecon"></div>

          <div class="sq sl"></div>
          <div class="sq sr"></div>

          <button type="submit" className="submit-btn" onClick={() => validateForm()}>
            SUBMIT
          </button>
        </div>
        <div className="socials mob">
          <div class="gentext">
            {" "}
            //GENERATING <br></br> SOCIAL LINKS FROM INDEX
          </div>
          <h2>FOLLOW US ON</h2>
          <div className="links">
            <a href="https://twitter.com/NFThing_" target="_blank">
              <BsTwitter className="icon" /> <p>TWITTER</p>
            </a>
            <a
              href="https://instagram.com/nfthing_?igshid=YmMyMTA2M2Y="
              target="_blank"
            >
              <BsInstagram className="icon" /> <p>INSTAGRAM</p>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default RegistrationForm;
// Git temp update
