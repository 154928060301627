import React from 'react';
import './TaskScreenOverlay.css'; // Make sure to create and import appropriate CSS for styling
import nextChevron from "../../../../assets/Images/nextchevron.svg";

const TaskScreenOverlay = ({ link, TaskSubmissionLink, TaskDiscordLink, onClose }) => {
  return (
    <div className="overlay">
      <div className="task-overlay-content">
        <button className="close-button" onClick={onClose}>X</button>
        <iframe src={link} width="100%" height="100%" title="Task" />
      </div>
      <div className='taskoverlay-button-container'>
      <div className='discord-container'>
      <div className='discord-text-container'>
      <h2 className='discord-text'> YOU GOT ANY DOUBTS ?</h2>
      </div>
      <a href={TaskDiscordLink} target="_blank" className='discord-button-container'>
      <button className='discord-button'> ASK ON DISCORD</button>
      </a>
      </div>
      <div className='task-button-container'>
      <a href='/help' target="_blank" className='taskSubmit-button-container'>
      <h4 className='taskSubmit-button-text'>GUIDELINES</h4>
      <img className='submit-chevron' src={nextChevron}/>
      </a>
      <a href='https://classroom.google.com/c/NjkxNTg0ODEwNTE0?cjc=jr7tqup' target="_blank" className='taskSubmit-button-container'>
      <h4 className='taskSubmit-button-text'>REGISTER HERE</h4>
      <img className='submit-chevron' src={nextChevron}/>
      </a>
      <a href={TaskSubmissionLink} target="_blank" className='taskSubmit-button-container'>
      <h4 className='taskSubmit-button-text'>SUBMIT TASK</h4>
      <img className='submit-chevron' src={nextChevron}/>
      </a>
      </div>
      </div>
    </div>
  );
};

export default TaskScreenOverlay;
