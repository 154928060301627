// import React, { useRef, useEffect,useState} from "react";
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import "./coursepagestyle.css";
import CourseSyllabus from "./CourseSyllabus";
import User from "./User";
//import { useUserContext } from '../CourseLogin/UserContext';
import Footer from '../../Footer/Footer';
import web3button from "../../../assets/Images/web3button.svg";
import coursesbutton from "../../../assets/Images/courses_red.svg";
import navContributors from "../../../assets/Images/nav_contri.svg";
import loginSignup from "../../../assets/Images/loginSignup.svg";
import mobilehamburger from "../../../assets/Images/mobham.svg";
import mobilelaptop from "../../../assets/Images/laptop.png";
import PlayButton from "../../../assets/Images/play_circle.svg";
import DeflixLogo from "../../../assets/Images/dFlix.svg";
import PauseButton from "../../../assets/Images/pause.svg"
import ReactGA from 'react-ga4';
const TRACKING_ID = "G-F6QZ1VY3NY";
ReactGA.initialize(TRACKING_ID, { debug_mode: true });




const CoursePage = () => {
  const [courseType, setCourseType] = useState('Frontend');
  const jwtToken = localStorage.getItem('jwtToken');
  const USN = localStorage.getItem('USN');
  const fullName = localStorage.getItem('fullName');
  const firstName = fullName ? fullName.split(' ')[0] : 'User';
  const navigate = useNavigate();
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [timelineProgress, setTimelineProgress] = useState(0);
  const videoRef = useRef(null);
  // const startTimeRef = useRef(Date.now());
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);
  const [isHovered, setIsHovered] = useState(false); 

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const updateProgress = () => {
    const video = videoRef.current;
    if (video) {
      const value = (video.currentTime / video.duration) * 100;
      setTimelineProgress(value);
    }
  };

  useEffect(() => {
    // Checking if the user is the default user or an actual logged-in user
    const isDefaultUser = jwtToken === 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NjlmNGEzMDJjN2NjMzcwMzYyMjNmOGYiLCJVU05udW1iZXIiOiIxVzNPMjAyNCIsImlhdCI6MTcyMTcxNTI4OH0.7DpOPyaq2LI09eawOJFjXSW7Bj-9ANkQ6WilsDfmABY' && USN === '1W3O2024'; // Replace 'default_jwt_here' with your default JWT
    
    // Determining if the user is a new or returning user based on whether they are the default user
    const isReturningUser = !isDefaultUser && jwtToken && USN ? true : false;
    
    // Loging a custom event in GA4 based on the user's status
    ReactGA.event({
      action: 'Edtech_login_status', 
      category: 'user_interaction', 
      label: isReturningUser ? 'Returning User' : (isDefaultUser ? 'Default User' : 'New User'),
      value: isReturningUser ? 1 : (isDefaultUser ? 0 : -1) 
    });
  
    // Tracking pageview using the correct method for react-ga4
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

    // // Function to calculate and send time spent
    // const calculateAndSendTimeSpent = () => {
    //   const endTime = Date.now();
    //   const timeSpent = (endTime - startTimeRef.current) / 1000; // Time spent in seconds
    //   console.log('Time Spent (in seconds):', timeSpent);
    //   const timeSpentInt = Math.round(timeSpent);

    //   // Send custom event to GA4
    //   ReactGA.event({
    //     action: 'coursepage_time_spent',
    //     category: 'user_engagement',
    //     label: 'Time Spent on Course Page',
    //     value: timeSpentInt, // Optional, track time spent in seconds
    //     nonInteraction: true, // Mark as non-interaction event
    //   });
    // };

    // // Send time spent data when the user navigates away or closes the page
    // window.addEventListener('beforeunload', calculateAndSendTimeSpent);

    // // Cleanup function to remove event listener
    // return () => {
    //   calculateAndSendTimeSpent(); // Send time spent when the component unmounts
    //   window.removeEventListener('beforeunload', calculateAndSendTimeSpent);
    // };
  }, [jwtToken, USN]); 

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      const updateProgress = () => {
        const value = (video.currentTime / video.duration) * 100;
        setTimelineProgress(value);
      };
  
      const handlePlay = () => {
        setIsVideoPlaying(true);
      };
  
      const handlePause = () => {
        setIsVideoPlaying(false);
      };
  
      video.addEventListener("timeupdate", updateProgress);
      video.addEventListener("play", handlePlay);
      video.addEventListener("pause", handlePause);
  
      return () => {
        video.removeEventListener("timeupdate", updateProgress);
        video.removeEventListener("play", handlePlay);
        video.removeEventListener("pause", handlePause);
      };
    }
  }, []);
  
  const togglePlay = () => {
    if (videoRef.current) {
      if (isVideoPlaying) {
        videoRef.current.pause();
        setIsVideoPlaying(false);
      } else {
        videoRef.current.play();
        setIsVideoPlaying(true);
      }
    }
  };
  
  

  const handleLogout = () => {
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('USN');
    localStorage.removeItem('fullName');
    localStorage.removeItem('userName');
    localStorage.removeItem('college');
    localStorage.removeItem('__v');

    //  // Send time spent data before navigating away
    //  const endTime = Date.now();
    //  const timeSpent = (endTime - startTimeRef.current) / 1000; // Time spent in seconds
    //  ReactGA.event({
    //    action: 'time_spent',
    //    category: 'user_engagement',
    //    label: 'Time Spent on Course Page before Logout',
    //    value: Math.round(timeSpent),
    //    nonInteraction: true,
    //  });
    navigate('/courselogin');
  };
  console.log("courseType in coursePage",courseType);

  return (
    <>
    <div>
    {isMobile ? (
        <div  style={{ backgroundColor: '#e7e7e7', minHeight: '100vh' }}>
          <div className='mobNavContainer'>
            <div className='mobNavBar'>
            <div className='mobnavW3o'>
            <div className='mobhamburg'>
              <img src={mobilehamburger} alt="hamburger"/>
            </div>
            <a href='/'>
            <div className='mobw3onavContainer'>web3onwards</div>
            </a>
            </div>
            </div>
          </div>
          <div className='mobBodyContainer'>
          {/* <div className="profile">
          <div className="profile-video-container"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            >
            { !isVideoPlaying && (
                  <div className="video-overlay">
                    <div className="overlay-content">
                      <div className="overlay-header-container">
                        <h3 className="overlay-header">What is web3onwards?</h3>
                      </div>
                      <div className="overlay-desciption-container">
                        <p className="overlay-text">Welcome to web3onwards, a community dedicated to the exploration, education, and innovation of decentralised technologies. Our mission is to empower the next generation of Marketers, Managers, Designers and Develop</p>
                      </div>
                      <div className="play-btn-container">
                      <img  className="play-btn"src={PlayButton} alt="Play Button"onClick={togglePlay}/>
                      </div>
                      <div className="playbutton-description-container">
                      <p className="playbutton-description">See what the students say <br></br>about web3 onwards</p>
                      </div>
                    </div>
                  </div>
                )}
              {/* <img className="logo" src={MSRITLogo} alt="MSRIT Logo" /> */}
              
              {/* <div className="timeline" style={{ width: `${timelineProgress}%` }}></div>
              <video
                src="https://webthreeonwards.blr1.cdn.digitaloceanspaces.com/w3o%20team.mp4"
                className="profile-vid"
                controls
                ref={videoRef}
              ></video>
               {isHovered && isVideoPlaying && (
          <img  className="pause-btn"src={PauseButton} onClick={togglePlay} alt="Pause Button " />
        
       
    )} */}
            {/* </div>
            <div className="user-details-container">
            <div className="name-container">
              <div className="name">NITIN GOEL</div>
              </div>
              <div className="box-1">
                <div className="deflix-contributor-container">
                <p className="text">
                  Contributor of{" "}
                  <span>
                    <img src={DeflixLogo} alt="" />
                  </span>{" "}
                </p>
                </div>
                <div className="role-box-container">
                <div className="box">Blockchain Developer</div>
                </div>
              </div>
            
            </div>
          </div> */} 
          <div className='mobredirectionContainer'>
          <div className='mobdirectionLaptopContainer'>
          <img className='laptopimg' src={mobilelaptop}  alt="PC"/>
          </div>
          <div className='mobdirection'>
          <div className='mobdirectionText'>Best viewed on <span className="mobdirectionTextspan">laptop</span> or your <span className="mobdirectionTextspan">personal Computer</span></div>
          </div>
          </div>
          </div>
        </div>
      ) : (
    <div style={{ backgroundColor: '#FFFFFF', minHeight: '100vh' }}>
    <div className="nav-bar-container">
          <div className="page-navigation-container1">
          <div className="nav-button-container">
          <a href='/'>
          <img src={web3button} alt="web3button"/>
          </a>
          </div>
          <div className="nav-button-container">
            <a href='/course'>
          <img src={coursesbutton} alt="coursesButton" />
          </a>
          </div>
          </div>
          <div className="coursepage-navigation-container">
          <div className="nav-button-container">
          <a href='/'>
          <img className='nav-contributors-button' src={navContributors} alt="web3button"/>
          </a>
          </div>
          {jwtToken && USN ? (
                <div className="nav-user-button-container">
                  <button className="user-button">Hi, {firstName}</button>
                  <button className="logout-button" onClick={handleLogout}>LOGOUT</button>
                </div>
                
              ) : (
                <div className="nav-button-container">
                <a href='/courselogin'>
                  <img className='nav-loginSignup-button' src={loginSignup} alt="loginSignup" />
                </a>
                </div>
              )}
          </div>
        </div>
    <User courseType={courseType}/>
    <CourseSyllabus setCourseType={setCourseType} />
    </div>
      )}
    </div>
    <Footer/>

    </>
  );
};

export default CoursePage;
