import React, { useEffect, useState } from "react";
import CourseContent from "../Course_Content/CourseContent";

const CourseContentBox = ({ tasksId, level, courseIdparam, linkOfTask, discordLink,submissionLink,fromGotoTask  }) => {
  const [courseId,setCourseId] = useState(courseIdparam);
  const [tasks,setTasks] = useState(tasksId)
  const [openLevel, setOpenLevel] = useState(level);
 // console.log("Level", level);
  
  useEffect(()=>{
    setCourseId(courseIdparam)
    console.log(courseIdparam)
  },[courseIdparam]);

  useEffect(()=>{
    setTasks(tasksId)
    console.log("tasksIdParam",tasksId)
  },[tasksId])

  const handleToggle = (currentLevel) => {
    setOpenLevel((prevLevel) => (prevLevel === currentLevel ? null : currentLevel));
  };

  if (!tasks) {
    return <div>Loading tasks...</div>;
  }

  const formatTime = (minutes) => {
    if (minutes < 60) {
      return `${minutes}mins`;
    }
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return mins > 0 ? `${hours} hr ${mins} mins` : `${hours} hr`;
  };
 
  return (
    <div className="box-2">
      <div className="content-header">
        <div className="content-heading">CONTENTS</div>
        <div className="content-text">{tasks.totalTasksInLevel.Beginner + tasks.totalTasksInLevel.Intermediate + tasks.totalTasksInLevel.Advanced} Tasks • {formatTime(tasks.totalTaskMinutesInLevel.Beginner + tasks.totalTaskMinutesInLevel.Intermediate + tasks.totalTaskMinutesInLevel.Advanced)} Long</div>
      </div>
      <CourseContent
        level="BEGINNER"
        time= {`${formatTime(tasks.totalTaskMinutesInLevel.Beginner)} Long`}
        circleColor="circle-green"
        initialTasks={tasks.beginnerTasks}
        open={openLevel === tasks.beginnerTasks[0]?.level}
        onToggle={() => handleToggle(tasks.beginnerTasks[0]?.level)}
        courseIdparam={courseId}
        fromGotoTask={fromGotoTask}
        linkOfTask={linkOfTask}
        discordLink={discordLink}
        submissionLink={submissionLink}
        
      />
      <CourseContent
        level="INTERMEDIATE"
        time={`${formatTime(tasks.totalTaskMinutesInLevel.Intermediate)} Long`}
        circleColor="circle-orange"
        initialTasks={tasks.intermediateTasks}
        open={openLevel === tasks.intermediateTasks[0]?.level}
        onToggle={() => handleToggle(tasks.intermediateTasks[0]?.level)}
        courseIdparam={courseId}
        
      />
      <CourseContent
        level="ADVANCED"
        time={`${formatTime(tasks.totalTaskMinutesInLevel.Advanced)} Long`}
        circleColor="circle-red"
        initialTasks={tasks.advancedTasks}
        open={openLevel === tasks.advancedTasks[0]?.level}
        onToggle={() => handleToggle(tasks.advancedTasks[0]?.level)}
        courseIdparam={courseId}
  
      />
    </div>
  );
};

export default CourseContentBox;
