// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/Images/long-border.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.frame-5b{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #000000;
    color: #ffffff;
}

.frame-5b .f5b-ques{
    font-family: PP-NueBit;
    margin:50px 0px;
    font-size: 30px;
    min-width: 360px
}

.frame-5b .f5b-access{
    display: flex;
    flex-direction: column;
    width: 85%;
    /* border-bottom: 1pt solid white; */
    padding: 50px 0px;
}

.frame-5b .long-border{
    width: 90%;
    height: 10px;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
}

.frame-5b .f5b-subtitle{
    font-family: PP-NueBit;
    font-size: 46px;
    margin: 1px 0px;

}

.frame-5b .f5b-desc{
    font-family: N27;
    font-size: 20px;
    margin: 5px;
    min-width: 330px;
}

.frame-5b .f5b-content{
    font-family: N27;
}`, "",{"version":3,"sources":["webpack://./src/components/Frame-5b/Frrame5b.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,sBAAsB;IACtB,eAAe;IACf,eAAe;IACf;AACJ;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,UAAU;IACV,oCAAoC;IACpC,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,yDAA4D;IAC5D,4BAA4B;AAChC;;AAEA;IACI,sBAAsB;IACtB,eAAe;IACf,eAAe;;AAEnB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":["\n.frame-5b{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    background: #000000;\n    color: #ffffff;\n}\n\n.frame-5b .f5b-ques{\n    font-family: PP-NueBit;\n    margin:50px 0px;\n    font-size: 30px;\n    min-width: 360px\n}\n\n.frame-5b .f5b-access{\n    display: flex;\n    flex-direction: column;\n    width: 85%;\n    /* border-bottom: 1pt solid white; */\n    padding: 50px 0px;\n}\n\n.frame-5b .long-border{\n    width: 90%;\n    height: 10px;\n    background-image: url(\"../../assets/Images/long-border.svg\");\n    background-repeat: no-repeat;\n}\n\n.frame-5b .f5b-subtitle{\n    font-family: PP-NueBit;\n    font-size: 46px;\n    margin: 1px 0px;\n\n}\n\n.frame-5b .f5b-desc{\n    font-family: N27;\n    font-size: 20px;\n    margin: 5px;\n    min-width: 330px;\n}\n\n.frame-5b .f5b-content{\n    font-family: N27;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
