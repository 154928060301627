import React, { useState, useEffect } from 'react'
import Header from "../../Header/Header"
import "./CaPage.css"
import {HiSearch} from "react-icons/hi"
import CaCard from '../../CaCard/CaCard'

const CaPage = ({device}) => {
  const matches=device;
  const studInfo=[
    {
      "firstname":"ROHAN",
      "lastname":"SANTOSH",
      "uni":"RAMAIAH INSTITUTE OF TECHNOLOGY",
      "course":"B.TECH (COMPUTER SCIENCE CYBER SECURITY)",
      "location":"BENGALURU, INDIA",
      "linkedin":"https://www.linkedin.com/in/rohansanthosh"
    },
    {
      "firstname":"NEHA",
      "lastname":"SANTOSH",
      "uni":"BMS INSTITUTE OF TECHNOLOGY",
      "course":"B.TECH (AI AND ML)",
      "location":"BENGALURU, INDIA",
      "linkedin":"https://www.linkedin.com/in/neha-santhosh-5aab62278"
    },
    {
      "firstname":"AMULYA ",
      "lastname": "A R",
      "uni":"RAMAIAH INSTITUTE OF TECHNOLOGY",
      "course":"B.TECH (AI AND ML)",
      "location":"BENGALURU, INDIA",
      "linkedin":"http://www.linkedin.com/in/amulya-a-r-b7a66322a"
    },
    {
      "firstname":"ARAVIND ",
      "lastname":"A R",
      "uni":"BMS INSTITUTE OF TECHNOLOGY",
      "course":"B.TECH (AI AND ML)",
      "location":"BENGALURU, INDIA",
      "linkedin":"https://www.linkedin.com/in/aravind-m-530114279"
    },
    {
      "firstname":"MOHAMMAD",
      "lastname":"RAZIM",
      "uni":"RAMAIAH INSTITUTE OF TECHNOLOGY",
      "course":"B.TECH (MECHANICAL ENGINEERING)",
      "location":"BENGALURU, INDIA",
      "linkedin":"https://www.linkedin.com/in/mohammed-razim-3b607123b"
    }
  ]

  const [filter, setfilter]=useState(false)

  const toggleFilter = ()=>{
    setfilter(!filter)
  }

  var filteredInfo=studInfo;

  const filterItems=(college)=>{
      filteredInfo=studInfo.filter(student=>{
        return student.uni==college
      })

  }
  

  return (
    <>
    <Header/>
   
<div className='main-grid'>
    <div class="grid-container">
    {
        filteredInfo.map((item) => (<CaCard data={item} device={matches}/>))
      } 
    </div>
</div>
<div className='search-grid'>
<div className="search-grid-item" style={{display:filter?"block":"none"}} >UNIVERSITY OF ARTS LONDON</div>
  <div className="search-grid-item" style={{display:filter?"block":"none"}}>UNIVERSITY OF ARTS LONDON</div>
  <div className="search-grid-item" style={{display:filter?"block":"none"}}>UNIVERSITY OF ARTS LONDON</div>  
  <div className="search-grid-item" style={{display:filter?"block":"none"}}>VELLORE INSTITUTE OF TECHNOLOGY</div>
  <div className="search-grid-item" style={{display:filter?"block":"none"}}>VELLORE INSTITUTE OF TECHNOLOGY</div>
  <div className="search-grid-item" style={{display:filter?"block":"none"}}onClick={filterItems("BMS INSTITUTE OF TECHNOLOGY")}>BMSIT</div>  
  <div className="search-grid-item" style={{display:filter?"block":"none"}}>RAMAIAH INSTITUTE OF TECHNOLOGY</div>
  <div className="search-grid-item" style={{display:filter?"block":"none"}}>RAMAIAH INSTITUTE OF TECHNOLOGY</div>
  <div className="search-grid-item" style={{display:filter?"block":"none"}} onClick={filterItems("RAMAIAH INSTITUTE OF TECHNOLOGY")}>RIT</div>   
  <div className='search-bar'>
    <div className='search-white'>
        <HiSearch/>
        <input className='input-field'/>
        <p>Search</p>
    </div>
  </div>
  <div className='filter'>
    <p onClick={toggleFilter}>UNIVERSITIES ▼</p>
    
    <p>
      A-Z ▼
    </p>
  
  </div>
  
</div>
    </>
  )
}

export default CaPage




