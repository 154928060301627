import React from "react";
import logo from "../logos/CentuRITan.svg";
import vector from "../logos/Vector.png";
import rea from "../logos/NFTHINGlogo.svg";
import "./header.css";
function Header() {
  return (
    <>
      <div className="navbar">
        <img src={rea} className="nfthing"></img>
        <img src={vector} className="vector"></img>
        {/* <img src={logo} className="rit"></img> */}
      </div>
    </>
  );
}
export default Header;
