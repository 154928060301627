import React from "react";
import "./Success.css";
import Header from "../header";
import DiscordButton from "../DiscordButton/discordButton";
import {
  BsLinkedin,

  BsInstagram,

} from "react-icons/bs";

const allowedUser = [
  "REGISTRATION SUCCESSFUL",
  "CONGRATULATIONS",
  "YOU'RE OFFICIALLY PART OF THE WEB3ONWARDS COMMUNITY! YOU NOW HAVE EXCLUSIVE ACCESS TO WEB3ONWARDS DISCORD SERVER AND START ENGAGING WITH OTHER MEMBERS. LET'S EXPLORE THE POTENTIAL OF WEB3 TOGETHER!",
];
const restrictedUser = [
  "REGISTRATION UNSUCCESSFUL",
  "YOU HAVE NOT REGISTERED :(",
  "PLEASE CONTACT YOUR CAMPUS AMBASSADOR FOR THE LINK TO JOIN THE DISCORD SERVER. CAN'T WAIT TO SEE YOU THERE!",
];

const CollegeUser = [
  "DETAILS SUBMITTED",
  "THANK YOU FOR FILLING OUT THE FORM.",
  "WE LOOK FORWARD TO CONNECTING WITH YOU AND SHOWCASE THE MANY OPPORTUNITIES AVAILABLE THROUGH THE WEB3ONWARDS COMMUNITY! FEEL FREE TO REACH OUT TO US AT [SANJANA]@WEB3ONWARDS.COM",
];




const Success = () => {
  console.log(typeof isAllowed)
  var isAllowed=false;
if(localStorage.getItem("isAllowed")==="true"){
  isAllowed=true
}else{
  isAllowed=false
}

 var user=localStorage.getItem("user")

  return (
    <>
    <div className="header">
      <Header />
      </div>
      <div className="main1">
        <div className="frame-bar">
          <div className="square"></div>
          <h3> {isAllowed?(user==="student"? allowedUser[0]:CollegeUser[0]): restrictedUser[0]}</h3>
        </div>
        <div className="congo">
          <h1>{isAllowed? (user==="student"? allowedUser[1]:CollegeUser[1]): restrictedUser[1]}</h1>
        </div>
        <div className="info">
          <h2>{isAllowed? (user==="student"? allowedUser[2]:CollegeUser[2]): restrictedUser[2]}</h2>
        </div>
        <div className="socials1">
          <div>
            <a target="_blank" href="https://www.linkedin.com/company/web3onwards/" rel="noreferrer">
              <BsLinkedin /> LINKEDIN 
            </a>
          </div>
          {isAllowed&&user==="student"?(<DiscordButton text="Join server" link="https://discord.gg/BMypfpc8Qa" style={{"marginRight":"0"}}/>):<></>}
          <div>
            <a target="_blank" href="https://www.instagram.com/web3onwards/" rel="noreferrer">
              <BsInstagram /> INSTAGRAM
            </a>
          </div>
        </div>
      </div>

      <div classname="dots_lines desktop">
        <div
          classname="sq"
          style={{ position: "absolute", left: "8.3vw", top: "33.5vh" }}
        ></div>
        <div
          classname="sq"
          style={{ position: "absolute", left: "42.5vw", top: "18.8vh" }}
        ></div>
        <div
          classname="sq"
          style={{ position: "absolute", left: "83.6vw", top: "28.5vh" }}
        ></div>
        <div
          classname="sq"
          style={{ position: "absolute", left: "92vw", top: "73.3vh" }}
        ></div>
        <div
          classname="sq"
          style={{
            position: "absolute",
            left: "92vw",
            top: "73.3vh",
            marginLeft: "15px",
          }}
        ></div>
        <div
          classname="sq"
          style={{
            position: "absolute",
            left: "19.8vw",
            top: "55vh",
            width: "8.3vw",
            maxWidth: "160px",
            transform: "rotate(90deg)",
          }}
        ></div>
        <div
          classname="sq"
          style={{
            position: "absolute",
            right: "15vw",
            top: "39vh",
            width: "8.3vw",
            maxWidth: "160px",
          }}
        ></div>

        <div
          classname="4square"
          style={{
            display: "block",
            position: "absolute",
            left: "6.35vw",
            top: "89.1vh",
          }}
        >
          <div style={{ display: "flex" }}>
            <div classname="sq3" style={{ position: "unset" }}></div>
            <div
              classname="sq3"
              style={{ marginLeft: "3px", position: "unset" }}
            ></div>
          </div>

          <div style={{ display: "flex", marginTop: "3px" }}>
            <div classname="sq3" style={{ position: "unset" }}></div>
            <div
              classname="sq3"
              style={{ marginLeft: "3px", position: "unset" }}
            ></div>
          </div>
        </div>
        <div
          classname="4square"
          style={{
            display: "block",
            position: "absolute",
            left: "40.3vw",
            top: "89.5vh",
          }}
        >
          <div style={{ display: "flex" }}>
            <div classname="sq3" style={{ position: "unset" }}></div>
            <div
              classname="sq3"
              style={{ marginLeft: "3px", position: "unset" }}
            ></div>
          </div>

          <div style={{ display: "flex", marginTop: "3px" }}>
            <div classname="sq3" style={{ position: "unset" }}></div>
            <div
              classname="sq3"
              style={{ marginLeft: "3px", position: "unset" }}
            ></div>
          </div>
        </div>
        <div
          classname="4square"
          style={{
            display: "block",
            position: "absolute",
            left: "74.3vw",
            top: "73.3vh",
          }}
        >
          <div style={{ display: "flex" }}>
            <div classname="sq3" style={{ position: "unset" }}></div>
            <div
              classname="sq3"
              style={{ marginLeft: "3px", position: "unset" }}
            ></div>
          </div>

          <div style={{ display: "flex", marginTop: "3px" }}>
            <div classname="sq3" style={{ position: "unset" }}></div>
            <div
              classname="sq3"
              style={{ marginLeft: "3px", position: "unset" }}
            ></div>
          </div>
        </div>
        <div
          classname="linedot"
          style={{
            position: "absolute",
            left: "47px",
            top: "54.7vh",
            transform: "rotate(90deg)",
          }}
        >
          <div classname="linethin"></div>
          <div classname="sq3" style={{ top: "-59px" }}></div>
          <div classname="sq3" style={{ top: "-71px", marginBottom: "12px" }}></div>
        </div>
        <div
          classname="linedot"
          style={{
            position: "absolute",
            left: "73.9vw",
            bottom: "47px",
            transform: "rotate(0deg)",
          }}
        >
          <div classname="linethin"></div>
          <div classname="sq3" style={{ top: "-59px" }}></div>
          <div classname="sq3" style={{ top: "-71px", marginBottom: "12px" }}></div>
        </div>
      </div>
      
      <div classname="dots_lines mob">
        <div
          classname="sq"
          style={{ position: "absolute", left: "8.3vw", top: "25.5vh" }}
        ></div>
        <div
          classname="sq"
          style={{ position: "absolute", left: "68.5vw", top: "18.8vh" }}
        ></div>
        <div
          classname="sq"
          style={{ position: "absolute", left: "85.6vw", top: "90.5vh" }}
        ></div>
        <div
          classname="sq"
          style={{ position: "absolute", left: "62vw", top: "73.3vh" }}
        ></div>
        <div
          classname="sq"
          style={{
            position: "absolute",
            left: "62vw",
            top: "73.3vh",
            marginLeft: "15px",
          }}
        ></div>
        <div
          classname="sq"
          style={{
            position: "absolute",
            left: "19.8vw",
            top: "65vh",
            height: "2px",
            width: "12.3vw",
            maxWidth: "160px",
          }}
        ></div>
        <div
          classname="sq"
          style={{
            position: "absolute",
            height: "1px",
            right: "0",
            top: "26vh",
            width: "10.3vw",
            maxWidth: "160px",
          }}
        ></div>

        <div
          classname="4square"
          style={{
            display: "block",
            position: "absolute",
            left: "6.35vw",
            top: "59.1vh",
          }}
        >
          <div style={{ display: "flex" }}>
            <div classname="sq3" style={{ position: "unset" }}></div>
            <div
              classname="sq3"
              style={{ marginLeft: "3px", position: "unset" }}
            ></div>
          </div>

          <div style={{ display: "flex", marginTop: "3px" }}>
            <div classname="sq3" style={{ position: "unset" }}></div>
            <div
              classname="sq3"
              style={{ marginLeft: "3px", position: "unset" }}
            ></div>
          </div>
        </div>

        <div
          classname="linedot"
          style={{
            position: "absolute",

            left: "47px",
            top: "14.7vh",
            // transform: "rotate(90deg)",
          }}
        >
          <div classname="linethin" style={{ width: "39px" }}></div>
        </div>
        <div
          classname="linedot"
          style={{
            position: "absolute",
            left: "23.9vw",
            bottom: "0px",
            transform: "rotate(0deg)",
          }}
        >
          <div classname="linethin" style={{ width: "50px" }}></div>
          <div classname="sq3" style={{ top: "-39px" }}></div>
          <div classname="sq3" style={{ top: "-51px", marginBottom: "12px" }}></div>
        </div>
      </div>

      

    </>
  );
};

export default Success;
