import React from "react";
import { useState } from "react";
import "./Frame4.css";
import { useEffect } from "react";
import { useRef } from "react";
import { useInView } from "framer-motion";

const Frame4 = () => {
  const [active, setActive] = useState(0);
  const frameFourRef = useRef();

  const isInView = useInView(frameFourRef);

  useEffect(() => {
    const calcPosition = (id) => {
      if (isInView) {
        const titleHeight = document.getElementById(
          `section-${id}`
        ).offsetHeight;
        const offsetHeight1 = document
          .getElementById(`scroll-section-${id}`)
          .getBoundingClientRect().top;

        const offsetHeightTopic1 =
          document.getElementById(`section-${id}`).getBoundingClientRect()
            .bottom -
          titleHeight / 2;

        if (offsetHeight1 <= offsetHeightTopic1) {
          setActive(id);
        } else if (id === 1 && offsetHeight1 >= offsetHeightTopic1) {
          setActive(0);
        }
      }
    };

    const getSectionPosStatus = () => {
      [1, 2, 3].map((item) => calcPosition(item));
    };

    document.addEventListener("scroll", getSectionPosStatus);

    return () => document.removeEventListener("scroll", getSectionPosStatus);
  }, [isInView]);
  return (
    <div className="frame-four-container">
      <div ref={frameFourRef} className="frame-four">
        <div className="frame-four-title active">
          <h1>HOW IT WORKS</h1>
        </div>
        <div className="frame-four-left">
          <div
            id="section-1"
            className={active === 1 ? "section active" : "section"}
          >
            <h2>Get acess via NFT ID</h2>
            <span>JOIN OUR COMMUNITY</span>
          </div>
          <div
            id="section-2"
            className={active === 2 ? "section active" : "section"}
          >
            <h2>EDUCATION on web3</h2>
            <span>LEARN WHAT THE NEW INTERNET IS ABOUT</span>
          </div>
          <div
            id="section-3"
            className={active === 3 ? "section active" : "section"}
          >
            <h2>BUILD the new internet</h2>
            <span>BECOME A PART OF THE COMMUNITY THAT BUILDS</span>
          </div>
        </div>
        <div className="frame-four-right">
          <div
            id="scroll-section-1"
            className={
              active === 1 ? "scroll-section active" : "scroll-section"
            }
          >
            In order to join our community, you will have to have a web3onwards
            NFT. This is available for free only to approved universities. Once
            your university is approved you will be able to mint an NFT. To get
            your university approved, please click.
            <a
              href="/form/contact"
              target="_blank"
              className="contact-us-btn"
              style={{ color: "black" }}
            >
              CONTACT US
            </a>
          </div>
          <div
            id="scroll-section-2"
            className={
              active === 2 ? "scroll-section active" : "scroll-section"
            }
          >
           Once you are in the community, we will upgrade your skills for the new internet. You will learn everything from technical skills, commercial knowledge to marketing and UI/UX that will be vital for web3 product development. In this exciting phase, you will get to collaborate with like-minded peers, network and learn. You're going to create value within the Web3 ecosystem. Through this process, you will not only have a good understanding of blockchain and web3 but also gain hands-on experience and perhaps even start your own blockchain initiative.
          </div>
          <div
            id="scroll-section-3"
            className={
              active === 3 ? "scroll-section active" : "scroll-section"
            }
          >
            Together we will build the new internet for the betterment of the common people from the comfort of your home. We will build apps and protocols to disrupt the AirBnb’s, Uber’s, Spotify’s, Meta’s and other aggregators that are taking money out of local systems. We will enable this money to flow back to the local communities.. in this way changing commerce for the good forever and bringing back power to the users and creators. It’s our data - don’t sell our data for us.. we can do that ourselves - thank you.

          </div>
        </div>
      </div>
    </div>
  );
};

export default Frame4;